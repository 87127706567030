@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.registration-section {
  background: url('../img/price-main-bg.png') no-repeat center;
  background-size: cover;
  padding: 52px 0 180px;
  min-height: 100vh;
  .container {
    max-width: 564px;
    padding: 0 10px;
    margin: 0 auto;
  }
  h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: $Scada;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .sup-text {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    max-width: 734px;
    text-align: center;
  }
  .sup-text-red {
    margin: 0 auto 25px;
    text-align: center;
    color: #e64f28;
    font-size: 13px;
  }
  .form-wrapper {
    max-width: 423px;
    padding: 0 10px;
    margin: 0 auto;
    text-align: center;
  }
  .tooltip-wrap {
    max-width: 347px;
    background: #fff;
    padding: 20px 35px;
    margin: 0 auto 15px;
    text-align: center;
    border: 2px solid #e3b87d;
    border-radius: 10px;
    .head {
      color: #8bba17;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
      font-family: $Scada;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #373737;
      margin-bottom: 15px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .field {
    position: relative;
    max-width: 403px;
    margin: 0 auto 44px;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
    input {
      border-radius: 30px;
      height: 60px;
      line-height: 60px;
      text-align: left;
      //padding: 0 160px 0 37px;
      font-size: 24px;
      &::placeholder {
        color: #cdcbcb;
      }
    }
    .register-success {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      margin-top: 10px;
    }
    .register-error {
      color: #e64f28;
      font-size: 22px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
  .card-wrap {
    color: #fff;
    max-width: 410px;
    margin: 0 auto;
    .text {
      float: left;
      font-size: 18px;
      line-height: 24px;
      font-family: $Scada;
      font-weight: bold;
      width: calc(100% - 106px);
    }
    img {
      margin-left: 15px;
      float: right;
      width: 91px;
      display: block;
    }
  }
}

@media screen and (max-width: 715px) {
  .registration-section {
    h1 {
      font-size: 30px;
      line-height: 1;
    }
    .sup-text {
      font-size: 20px;
      line-height: 24px;
    }
    .field {
      margin-bottom: 20px;
      input {
        line-height: 45px;
        height: 45px;
        font-size: 20px;
      }
      button {
        height: 45px;
        line-height: 45px;
      }
    }
    .tooltip-wrap {
      padding: 10px 15px;
    }
  }
}

@media screen and (max-width: 410px) { 
  .registration-section {
    .field {
      input {
        text-align: center;
        margin-bottom: 10px;
      }
      button {
        position: static;
      }
    }
  }
}