// fonts
$OpenSans: 'Open Sans',
sans-serif;
$Scada: 'Scada',
sans-serif;
// weight
$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;
$black: 900;
// slick
$slick-font-path: "/node_modules/slick-carousel/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/node_modules/slick-carousel/slick-carousel/slick/" !default;
$slick-arrow-color: black !default;
