@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.winners-section {
  background: url('../img/price-main-bg.png') no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh;
  .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .wrapper {
    .tabs-list {
      a {
        text-decoration: none;
      }
    }
  }
  .tabs-container {
    padding: 44px 10% 24px;
    background: url('../img/winners-bg.png') no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: $Scada;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .sup-text {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    max-width: 734px;
    margin: 0 auto 25px;
    text-align: center;
  }
  .empty-winners {
    text-align: left;
  }
  .tabs-container {
    img {
      float: left;
      height: 89px;
      margin-right: 40px;
    }
    table {
      overflow: hidden;
      width: calc(100% - 142px);
      box-sizing: border-box;
      margin-bottom: 39px;
      tr {
        td {
          font-size: 16px;
          line-height: 38px;
          &:nth-child(2),
          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 845px) {
  .tabs-list-item {
    width: 44%;
    margin: 0 2% 10px;
  }
}

@media screen and (max-width: 715px) {
  .winners-section {
    h1 {
      font-size: 30px;
      line-height: 1;
    }
    .sup-text {
      font-size: 20px;
      line-height: 24px;
    }
    .tabs-container {
      padding: 25px 15px 15px;
      img {
        display: none;
      }
      .inner {
        overflow: scroll;
      }
      table {
        text-align: center;
        width: 100%;
        min-width: 450px;

      }
    }
  }
}
