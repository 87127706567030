@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.products-section {
  text-align: center;
  .img-box {
    img {
      display: inline-block;
      vertical-align: middle;
      margin: 2px 6px;
    }
  }
  h2 {
    font-size: 60px;
    line-height: 1.1;
    color: $green-light;
    margin-bottom: 20px;
    font-family: $Scada;
  }
  .sub-text {
    color: #373737;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}

.products-section .tabs {
  &-container {
    position: relative;
  }
  &-list {
    padding: 10px 0;
    max-width: 1220px;
    padding: 0 15px;
    margin: 0 auto 30px;
  }
  &-list-item {
    cursor: pointer;
    margin: 0 33px;
    $w: 240px;
    $h: 106px;
    $c: #ffffff;
    width: 238px;
    height: 104px;
    display: inline-block;
    vertical-align: middle;
    color: #9ccb3b;
    @include super-rectangle($w, $h, $c);
    @include shadow-plain-left($w, $h, #9ccb3b);
    @include shadow-plain-right($w, $h, #9ccb3b);
    transition: 0.3s ease all;
    text-align: center;
    padding-top: 28px;
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    &:hover,
    &.js-active {
      $w: 240px;
      $h: 106px;
      $c: #9ccb3b;
      @include super-rectangle($w, $h, $c);
      color: #fff;
    }
    span {
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      display: block;
    }
    &.second {
      width: 291px;
      height: 104px;
      $w: 294px;
      $h: 106px;
      $c: white;
      height: $h;
      display: inline-block;
      vertical-align: middle;
      @include super-rectangle($w, $h, $c);
      @include shadow-plain-left($w, $h, #9ccb3b);
      @include shadow-plain-right($w, $h, #9ccb3b);
      &:hover,
      &.js-active {
        $w: 294px;
        $h: 106px;
        $c: #9ccb3b;
        @include super-rectangle($w, $h, $c);
      }
    }
    &.third {
      width: 426px;
      height: 106px;
      $w: 428px;
      $h: 108px;
      $c: white;
      height: $h;
      display: inline-block;
      vertical-align: middle;
      @include super-rectangle($w, $h, $c);
      @include shadow-plain-left($w, $h, #9ccb3b);
      @include shadow-plain-right($w, $h, #9ccb3b);
      &:hover,
      &.js-active {
        $w: 426px;
        $h: 106px;
        $c: #9ccb3b;
        @include super-rectangle($w, $h, $c);
      }
    }
  }
  &-container-tab {
    min-height: 660px;
    background: url('../img/section-bg1.png') no-repeat left center;
    background-size: cover;
    display: none;
    &.js-active {
      display: block;
    }
    .inner {
      max-width: 940px;
      margin: 0 0 0 33%;
      padding-bottom: 138px;
      position: relative;
      padding: 0 40px 138px;
      .pic1 {
        width: 127px;
        height: 284px;
      }
      .pic2 {
        width: 137px;
        height: 303px;
      }
      .pic3 {
        width: 131px;
        height: 293px;
      }
      .pic4 {
        width: 105px;
        height: 232px;
      }
      .pic5 {
        width: 102px;
        height: 231px;
      }
      .pic6 {
        width: 103px;
        height: 229px;
      }
      .pic7 {
        width: 104px;
        height: 229px;
      }
      .pic8 {
        width: 103px;
        height: 228px;
      }
      .pic9 {
        width: 103px;
        height: 230px;
      }
      .pic10 {
        width: 101px;
        height: 226px;
      }
      .pic11 {
        width: 128px;
        height: 282px;
      }
      .pic12 {
        width: 130px;
        height: 290px;
      }
      .pic13 {
        width: 128px;
        height: 282px;
      }
    }
    .massa-img {
      margin-bottom: 60px;
    }
    .round-img {
      margin-bottom: 15px;
    }
    .round-text {
      display: none;
      color: #54a640;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 15px;
    }
    .marks {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 37px;
    }
  }
}

.products-section .tabs-container-tab.second {
  background-image: url('../img/section-bg2.png') !important;
}

.products-section .tabs-container-tab.third {
  background-image: url('../img/section-bg3.png') !important;
}

.products-section .tab-next,
.products-section .tab-prev {
  position: absolute;
  margin: auto;
  width: 36px;
  height: 53px;
  top: 320px;
  left: 0;
  cursor: pointer;
  z-index: 5;
  text-indent: -9999px;
  background: url('../img/arrow-left-2.png') no-repeat center !important;
}

.products-section .tab-next {
  left: inherit;
  right: 20px;
  background-image: url('../img/arrow-right-2.png') !important;
}

@media screen and (max-width: 1200px) {
  .products-section .tabs-list {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .products-section .tabs-list-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 33% !important;
    height: 100px !important;
    padding: 0 !important;
    border: 3px solid $green-light;
    margin: 0 auto;
    border-radius: 10% 10% 0 0;
    border-bottom: 0;
    text-align: center;
    background: #fff !important;
    &::before,
    &:after {
      display: none !important;
    }
    &.js-active,
    &:hover {
      background: $green-light !important;
      &::before,
      &:after {
        display: none !important;
      }
    }
    &.second {
      margin: 0 -9px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .products-section .tabs-container-tab .inner {
    max-width: none;
    margin: 0;
    .round-img {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-radius: 15px;
    }
  }
  .products-section .tab-prev {
    left: 30px;
  }
}

@media screen and (max-width: 980px) {
  .products-section .tabs-container-tab {
    .img-box {
      max-width: 494px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 860px) {
  .products-section .tabs-container-tab {
    .round-img {
      display: none;
    }
    .img-box {
      max-width: 400px;
    }
    .round-text {
      max-width: 80%;
      border-radius: 15px;
      margin: 0 auto 30px;
      font-weight: bold;
      display: block;
      padding: 10px;
      background: rgba(255, 255, 255, 0.8);
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@media screen and (max-width: 670px) {
  .products-section .tabs-container-tab {
    .round-img {
      display: none;
    }
    .round-text {
      display: block;
      padding: 10px;
      background: rgba(255, 255, 255, 0.8);
      font-size: 20px;
      line-height: 27px;
    }
  }
  .products-section .tabs-list-item {
    height: 75px !important;
    font-size: 18px;
    span {
      display: none;
    }
  }
  .products-section .tab-next,
  .products-section .tab-prev {
    top: calc(50% - 36px);
  }
  .products-section .tab-next {
    right: 5px;
  }
  .products-section .tab-prev {
    left: 5px;
  }
}