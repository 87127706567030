// colors
$transparent: rgba(0, 0, 0, 0) !default;
$black: #000 !default;
$black-light: #373737 !default;
$grey: #cacaca !default;
$white: #fff !default;
$green: #54a640 !default;
$green-light: #9ccb3b !default;
$red2: #e64f28;
$red: firebrick;
$yellow: #f8e534;
