@import './colors';
@import './mixins';
@import './vars';
.navigation {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url('../img/menu-bg.png') no-repeat center;
  background-size: cover;
  z-index: 102;
  display: none;
  &.js-active {
    display: block;
  }
  .candy-img {
    position: absolute;
    z-index: 1;
    width: 844px;
    left: 0;
    top: 0;
  }
  .nav-wrap {
    position: relative;
    z-index: 2;
    padding-top: 28px;
    padding-right: 43px;
    width: 100%;
    text-align: right;
  }
  .top-btn,
  .spliter {
    font-family: $Scada;
    font-size: 26px;
    color: #fff;
    font-weight: normal;
    position: relative;
    top: -3px;
  }
  .spliter {
    margin: 0 4px;
  }
  .menu-btn2-closer {
    width: 24px;
    height: 24px;
    margin-left: 77px;
    background: url('../img/close-red.svg') no-repeat center;
    background-size: 100% 100%;
  }
  .nav-list {
    margin-top: 90px;
    padding-right: 107px;
    list-style: none;
    li {
      margin-bottom: 44px;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        font-family: $Scada;
        font-size: 34px;
        color: #fff;
        line-height: 1;
        display: inline;
      }
    }
  }
}

.pages-menu {
  .menu-btn2 {
    width: 33px;
    height: 23px;
    position: absolute;
    right: 41px;
    top: 29px;
  }
}

@media screen and (max-width: 1215px) {
  .navigation {
    .candy-img {
      width: calc(100% - 400px);
    }
  }
}

@media screen and (max-width: 895px) {
  .navigation {
    .candy-img {
      display: none;
    }
  }
}

@media screen and (max-width: 715px) {
  .navigation {
    .nav-wrap {
      padding: 10px 15px;
    }
  }
  .pages-menu {
    .menu-btn2 {
      top: 10px;
      right: 15px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .navigation {
    .nav-list {
      padding-right: 0;
      margin-top: 30px;
      li {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .navigation {
    .top-btn,
    .spliter {
      margin-right: 0;
      float: left;
      font-size: 20px;
      top: 0
    }
    .spliter {
      top: 2px;
      margin: 0 2px;
    }
    .menu-btn2-closer {
      margin-left: 10px;
    }
    .nav-list {
      padding-right: 0;
      margin-top: 15px;
      li {
        margin-bottom: 15px;
        a {
          font-size: 20px;
        }
      }
    }
  }
}