@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.info-section {
  position: relative;
  padding-bottom: 72px;
  .info-section-bg-image {
    width: 106%;
    left: -3%;
    display: block;
    max-width: none;
    position: absolute;
    top: 0;
    z-index: 1;
    min-height: 770px;
  }
  .container {
    position: relative;
    z-index: 2;
    margin-bottom: 88px;
  }
  .bg-wrap {
    min-height: 951px;
  }
  .text {
    width: 50%;
    padding-top: 200px;
    float: left;
    p {
      max-width: 608px;
      font-size: 26px;
      line-height: 40px;
      color: #fff;
    }
  }
  .img-box {
    margin-right: 70px;
    width: 328px;
    float: right;
    img {
      width: 106px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
    }
    .pic1 {
      width: 106px;
    }
    .pic2 {
      width: 106px;
      top: -19px;
    }
    .pic3 {
      width: 106px;
    }
    .pic4 {
      width: 106px;
      right: -18px;
      z-index: 2;
    }
    .pic4,
    .pic6 {
      top: -67px
    }
    .pic5 {
      width: 106px;
      top: -86px;
      z-index: 1;
    }
    .pic6 {
      width: 106px;
      left: -18px;
    }
  }
  h2 {
    clear: both;
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    font-family: $Scada;
    margin-left: 45%;
    line-height: 1;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      top: 35px;
      right: calc(100% + 29px);
      width: 184px;
      height: 107px;
      background: url('../img/arrow-down.png') no-repeat center;
    }
  }
  .info-gif-image,
  .info-gif-image-mobile {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 4;
  }
  .info-gif-image-mobile {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .info-section {
    .info-gif-image,
    .info-gif-image-mobile {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1140px) {
  .info-section {
    h2 {
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
    }
  }
}

@media screen and (max-width: 960px) {
  .about-section {
    .left-img-box {
      width: 50%;
      margin: 0 auto;
    }
    .text {
      width: 50%;
    }
    .right-img-box {
      width: 50%;
      float: none;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 840px) {
  .info-section {
    .info-section-bg-image {
      min-height: 1400px;
    }
    .info-gif-image {
      display: none;
    }
    .info-gif-image-mobile {
      display: block;
      width: 390px;
    }
  }
}

@media screen and (max-width: 768px) {
  .info-section {
    .text {
      float: none;
      width: 100%;
      margin: 0 auto 60px;
      p {
        text-align: center;
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto 15px;
      }
    }
    .img-box {
      margin: 0 auto;
      float: none;
    }
  }
}

@media screen and (max-width: 755px) {
  .about-section {
    .left-img-box,
    .text .right-img-box {
      width: 50%;
      float: none;
      margin: 0 auto;
      img {
        margin: 0 auto;
      }
    }
    .text {
      width: 100%;
      padding: 0;
      margin: 35px auto;
      p {
        &::before,
        &:after {
          display: none;
        }
      }
    }
  }
  .about-section-logo-img {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 800px) {
  .info-section h2:before {
    right: initial;
    top: 17px;
    left: -94px;
    width: 103px;
    height: 107px;
    background-size: contain;
    transform: rotate(-29deg);
  }
}

@media screen and (max-width: 470px) {
  .about-section .text p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 400px) {
  .info-section {
    .img-box {
      width: 310px;
      height: 452px;
      text-align: center;
      img {
        position: absolute;
        top: 100px!important;
        right: 0!important;
        bottom: 0!important;
        left: 0!important;
        margin: auto;
        &.pic1,
        &.pic4{
          right: 170px!important;
        
        }
        &.pic5,
        &.pic2 {
          top: 70px!important;
        }
        &.pic3,
        &.pic6{
          left: 170px!important;
        }
        &.pic4,
        &.pic6 {
          top: 500px!important;
        }
        &.pic5 {
          top: 470px!important;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .info-section {
    h2 {
      margin-left: 30%;
      font-size: 40px;
    }
  }
}

.fb-phone-agree {
  margin-bottom: 15px;
  margin-top: 15px;
}