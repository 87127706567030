@import "./colors";
@import "./mixins";
@import "./vars";
// styles
.field {
  width: 100%;
  input {
    padding: 0 12px;
    line-height: 46px;
    height: 40px;
  }
  textarea {
    line-height: 1.2;
    height: 109px;
    padding: 9px 12px;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    border-radius: 3px;
    text-align: center;
    border: 0;
    border-radius: 20px;
    font-family: $OpenSans;
    font-weight: $regular;
    font-weight: 400;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    &.invalid {
      border-color: $red2;
    }
    &.valid {
      border-color: green;
    }
  }
  label.error {
    font-size: 14px;
    position: absolute;
    top: 100%;
    left: 0;
    color: $red2;
  }
}

.button,
a.button {
  font-family: $OpenSans;
  display: inline-block;
  font-size: 18px;
  padding: 0 36px;
  color: #fff;
  font-weight: normal;
  border-radius: 4px;
  background-color: $red2;
  height: 60px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  background-color: $red2;
}

ul.circle-list {
  list-style: none;
  &>li {
    padding-left: 19px;
    position: relative;
    line-height: 1.5;
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      overflow: hidden;
      background: $red2;
    }
  }
}

.icon-arrow {
  border: solid $grey;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  &--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

// i.icon {
//   position: relative;
//   display: inline-block;
//   vertical-align: middle;
//   color: #fff;
//   &.point {
//     width: 23px;
//     height: 31px;
//     background: url('../img/icons/point.svg') no-repeat center;
//   }
//   &.phone {
//     width: 30px;
//     height: 30px;
//     background: url('../img/icons/phone.svg') no-repeat center;
//   }
//   &.message {
//     width: 27px;
//     height: 28px;
//     background: url('../img/icons/message.svg') no-repeat center;
//   }
//   &.instagram {
//     width: 50px;
//     height: 50px;
//     background: url('../img/icons/instagram.svg') no-repeat center;
//   }
//   &.facebook {
//     width: 47px;
//     height: 47px;
//     background: url('../img/icons/facebook.svg') no-repeat center;
//   }
// }
.flip-clock-divider {
  display: none;
}

.flip-clock-wrapper {
  width: 219px;
  margin: 0 auto;
  text-align: center;
}

.flip-clock-wrapper ul {
  width: 24px;
  height: 48px;
  line-height: 48px;
  font-size: 40px;
  margin: 0;
  border-radius: 4px;
  margin-right: 2px;
  &:nth-of-type(8),
  &:nth-of-type(6),
  &:nth-of-type(4),
  &:nth-of-type(2) {
    margin-right: 6px;
    &::before {
      content: "qwe";
      position: absolute;
      color: $red2;
      left: -11px;
      font-size: 10px;
      top: 33px;
      z-index: 101;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  li {
    font-size: 40px;
    line-height: 48px;
  }
  &:nth-of-type(8) {
    &:before {
      content: "СЕК";
    }
  }
  &:nth-of-type(6) {
    &:before {
      content: "ХВ";
      left: -8px;
    }
  }
  &:nth-of-type(4) {
    &:before {
      content: "ГОД";
    }
  }
  &:nth-of-type(2) {
    &:before {
      content: "ДНIВ";
    }
  }
}

.flip-clock-wrapper ul li a div {
  font-size: 40px;
}

.flip-clock-wrapper ul li a div div.inn {
  font-size: 40px;
  font-weight: normal;
}

.flip-clock-wrapper {
  .flip {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: $yellow;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 101;
    }
  }
}

.field-checkbox {
  input[type='checkbox'] {
    display: none !important;
  }
  label {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    line-height: 26px;
    color: #222;
    padding-left: 23px !important;
    margin: 0 !important;
    position: relative;
    &:after {
      background: $red2;
      width: 14px;
      height: 14px;
      display: none;
      content: '';
      position: absolute;
      z-index: 2;
      left: 1px;
      top: -13px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 14px;
      height: 14px;
      z-index: 1;
      border: 1px solid #fff;
      background: none;
    }
  }
  input[type=checkbox]:checked+label:after {
    display: block;
  }
}

.tabs {
  &-container {
    position: relative;
  }
  &-list {
    margin-bottom: 18px;
  }
  &-list-item {
    cursor: pointer;
    margin: 0 13px;
    width: 172px;
    height: 57px;
    line-height: 55px;
    background: url('../img/tab-bg.png') no-repeat center;
    background-size: 100% 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    transition: 0.3s ease all;
    text-align: center;
    font-size: 18px;
    &.js-active {
      background: url('../img/tab-bg2.png') no-repeat center;
      background-size: 100% 100%;
      color: #9ccb3b;
    }
  }
  &-container-tab {
    display: none;
    &.js-active {
      display: block;
    }
  }
}

.pagination {
  text-align: center;
  clear: both;
  ul {
    li {
      margin: 0 3px;
      display: inline-block;
      a, span {
        font-size: 18px;
        line-height: 30px;
        color: #959595;
        text-decoration: none;
        font-weight: bold;
      }
      &.active {
        a, span {
          color: #e64f28;
        }
      }
    }
  }
}

.triangl-btm {
  position: relative;
  z-index: 2;
}

.triangl-btm:after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: calc(100% - 10px);
  right: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border-bottom: 2px solid #e3b87d;
  border-left: 2px solid #e3b87d;
  transform: rotate(-45deg);
}






// styles
ul.circle-list {
  list-style: none;
  &>li {
    padding-left: 19px;
    position: relative;
    line-height: 1.5;
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      overflow: hidden;
      background: $red;
    }
  }
}

.icon-arrow {
  border: solid $grey;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  &--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.slider-section .slick-slide {
  // margin: 0 8px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

// slick
.slider-section .slick-next,
.slider-section .slick-prev {
  position: absolute;
  top: 107px;
  margin: auto;
  width: 36px;
  height: 53px;
  left: -73px;
  z-index: 5;
  text-indent: -9999px;
  background: url('../img/arrow-left-2.png') no-repeat center !important;
}

.slider-section .slick-next {
  left: inherit;
  right: -73px;
  background-image: url('../img/arrow-right-2.png') !important;
}

.slider-section .slick-dots {
  top: 65px;
  text-align: center;
  position: relative;
  &>li {
    display: inline-block;
    margin: 0 6px;
    width: auto;
    height: auto;
    button {
      border-radius: 50%;
      text-indent: -9999px;
      width: 21px;
      height: 21px;
      background-color: #cacaca;
      transition: .3s ease all;
    }
    &.slick-active,
    &:hover {
      button {
        background: #bfd434;
      }
    }
  }
}

.news-section .slick-next,
.news-section .slick-prev {
  position: absolute;
  top: 40px;
  margin: auto;
  width: 36px;
  height: 53px;
  left: -73px;
  z-index: 5;
  text-indent: -9999px;
  background: url('../img/arrow-left-2.png') no-repeat center !important;
}

.news-section .slick-next {
  left: inherit;
  right: -73px;
  background-image: url('../img/arrow-right-2.png') !important;
}

.news-section .slick-dots {
  top: 70px;
  text-align: center;
  position: relative;
  &>li {
    display: inline-block;
    margin: 0 6px;
    width: auto;
    height: auto;
    button {
      border-radius: 50%;
      text-indent: -9999px;
      width: 21px;
      height: 21px;
      background-color: #cacaca;
      transition: .3s ease all;
    }
    &.slick-active,
    &:hover {
      button {
        background: #bfd434;
      }
    }
  }
}

i.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  &.point {
    width: 23px;
    height: 31px;
    background: url('../img/icons/point.svg') no-repeat center;
  }
  &.phone {
    width: 30px;
    height: 30px;
    background: url('../img/icons/phone.svg') no-repeat center;
  }
  &.message {
    width: 27px;
    height: 28px;
    background: url('../img/icons/message.svg') no-repeat center;
  }
  &.instagram {
    width: 50px;
    height: 50px;
    background: url('../img/icons/instagram.svg') no-repeat center;
  }
  &.facebook {
    width: 47px;
    height: 47px;
    background: url('../img/icons/facebook.svg') no-repeat center;
  }
}


/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: ( squeeze,
) !default;
@if index($hamburger-types,
        squeeze) {
  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }
      &::after {
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}


// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function; // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    @if $hamburger-hover-use-filter==true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;
  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}


.circle {
  position: relative;
  z-index: 10;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  transform: rotate(-12deg);
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 30px;
  animation-timing-function: cubic-bezier(0, 0.3, 1, 0.7);
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  img {
    z-index: -1;
    display: block;
    position: relative;
    transform: rotate(12deg);
  }
  &:last-of-type {
    margin-right: 0;
  }
}

@keyframes example {
  from {top: -600%;}
  to {top: 120%;}
}


.circle:before, .circle:after {
  content:'';
  display: block;
  position: absolute;
  height: 100%;
}
.circle:before {
  width: 140%;
  top: inherit;
  left: -20%;
  opacity: 0.6;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 40%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0));
  transform: rotate(-20deg);
}
.circle:after {
  width: 100%;
  top: 0;
  border-radius: 50%;
  box-shadow: inset -5px -15px 40px rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(35% 25%, closest-corner, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}

#user_details {
  text-align: center;
  label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .buttons {
    padding-top: 15px;
    .btn {
      font-family: Open Sans,sans-serif;
      display: inline-block;
      font-size: 18px;
      padding: 0 36px;
      color: #fff;
      font-weight: 400;
      height: 50px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      border-radius: 30px;
      background-color: #e64f28;
      border: none;
      cursor: pointer;
    }
  }
  .text-danger {
    color: #e64f28;
  }
  input {
    width: 100%;
    text-align: center;
    border-radius: 20px;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    border: 1px solid #9ccb3b;
    padding: 0 12px;
    height: 40px;
    max-width: 300px;
  }
}