@import "./colors";
@import "./mixins";
@import "./vars";

/*--------------GLOBAL RESET-----------------*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:-moz-placeholder,
*::-moz-placeholder,
*:-ms-input-placeholder,
*::-webkit-input-placeholder{
  color: rgb(205, 203, 203);
  opacity: 1;
  text-align: center;
}

*:focus::-webkit-input-placeholder,
*:focus:-ms-input-placeholder,
*:focus::-moz-placeholder,
*:focus:-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.slick-slider,
.select2 {
  * {
    outline: 0;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn,
em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, menu, nav, output, ruby, section,
summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, menu, nav,
section, main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

ul.reset {
  list-style: none;
}

ul.circle-list {
  list-style: none;
  li {
    padding-left: 13px;
    position: relative;
    line-height: 1.2;
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      overflow: hidden;
      background: red;
    }
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

button {
  outline: 0;
  border: 0;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

input, textarea {
  outline: 0;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

/*--------------END GLOBAL RESET-----------------*/

/*--------------CUSTOM RESET-----------------*/

html, body {
  font-size: 14px;
  line-height: 21px;
  font-family: $OpenSans;
  font-weight: 400;
  color: $black-light;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 auto;
  overflow: hidden;
}

a {
  text-decoration: none;
  transition: .3s ease all;
  &:hover {
    text-decoration: underline;
  }
}

a[href^="tel:"] {
  &:hover {
    text-decoration: none
  }
}


.container{
  max-width: 1220px;
  padding: 0 15px;
  margin: 0 auto;
}

.hide {
  display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}
section.promo-section {
  min-height: 100vh;
}

/*-----------END CUSTOM RESET----------------*/
