@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.about-section {
  padding-top: 44px;
  padding-bottom: 46px;
  .left-img-box,
  .text,
  .right-img-box {
    text-align: center;
    float: left;
    img {
      display: block;
    }
  }
  .right-img-box {
    width: 28%;
    float: right;
    position: relative;
    &:after {
      width: 41px;
      height: 34px;
      content: '';
      position: absolute;
      bottom: -10px;
      right: 20px;
      background: url('../img/zerno7.png') no-repeat center;
      background-size: contain;
    }
  }
  .left-img-box {
    width: 25%;
  }
  .text {
    width: 47%;
    padding: 97px 10px 0;
    p {
      max-width: 475px;
      position: relative;
      text-align: center;
      color: #6f902a;
      font-size: 26px;
      line-height: 36px;
      font-weight: 400;
      margin: 0 auto;
      &:before {
        content: '';
        position: absolute;
        top: -98px;
        right: 97px;
        width: 79px;
        height: 47px;
        background: url('../img/zerno1.png') no-repeat center;
        background-size: contain;
      }
      &:after {
        width: 74px;
        height: 95px;
        content: '';
        position: absolute;
        bottom: -133px;
        right: 39%;
        background: url('../img/zerno2.png') no-repeat center;
        background-size: contain;
      }
    }
  }
  &-logo-img {
    margin-top: 11px;
    margin-bottom: 32px;
    margin-right: auto;
  }
  &-exp-img {
    margin-right: auto;
  }
  &-halva-img {
    margin-left: auto;
  }
}

@media screen and (max-width: 500px) {
  .about-section {
    .right-img-box {
      &:after {
        transform: scale(0.7);
        bottom: -23px;
        right: -8px;
      }
    }
  }
}