@import './colors';
@import './mixins';
@import './vars';

.overlay {
  background: rgba(255,255,255,.4);
  position: fixed;
  z-index: -1;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &.active {
    visibility: visible;
    z-index: 111;
  }
}


.modal {
  z-index: 12;
  top: -1000px;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  transition: .3s ease all;
  visibility: hidden;
  width: auto;
  max-width: 574px;
  border-radius: 5px;
  background: #000;
  z-index: 122;
  &.active {
    visibility: visible;
  }
  &-closer {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
    &::before, &:after {
      position: absolute;
      content: '';
      top: 7px;
      border-radius: 4px;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }
  &-head {
    padding: 14px 0 0 0;
    text-align: center;
  }
  &-body {
    overflow: hidden;
    max-width: 710px;
    padding: 0 10px 42px;
    margin: 0 auto;
    .row {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}



.registration-modal, .login-modal, .forgotten-modal, .recall-modal, .stock-modal {
  background: url('../img/reg-modal-bg.jpg') no-repeat center;
  background-size: cover;
  .inner {
    max-width: 331px;
    margin: 0 auto;
  }
  .logo {
    width: 89px;
    height: 89px;
    margin: 0 auto 12px;
  }
  h2 {
    font-family: $Scada;
    font-size: 28px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 12px;
    color: #fff;
  }
  .field {
    margin-bottom: 20px;
    position: relative;
  }
  .captch-wrap {
    height: 75px;
    background: #fff;
    margin-bottom: 20px;
  }
  .row {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  span {
    font-family: $OpenSans;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    display: block;
    text-align: center;
    margin: 0 auto 15px;
  }
  button.button, a.button {
    display: block;
    width: 100%;
    max-width: none;
    padding: 0;
  }
  label {
    font-family: $OpenSans;
    font-size: 14px;
    line-height: 18px;
    p {
      margin: -20px 0 -20px 24px;
      color: #fff;
      overflow: hidden;
    }
    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .field-checkbox {
    float: left;
  }
  .enter-btn {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
  }
  a.link {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0 7px;
  }
  .fb-btn {
    margin-right: 13px;
  }
  a.button.reg-btn, a.button.fb-btn {
    width: auto;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 19px;
    margin-bottom: 10px;
  }
}
.stock-h3 {
  color: #fff;
  padding-bottom: 10px;
  max-width: 1060px;
  margin: 0 auto;
}
.stock-modal {
  margin-bottom: 30px;
}
.login-modal {
  .moda-body {
    padding-bottom: 32px;
  }
  .inner {
    max-width: 399px;
    text-align: center;
  }
}
.form-success {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}
.form-error {
  color: #e64f28;
  font-size: 16px;
  margin-bottom: 5px;
}