@import './components/colors';
@import './components/mixins';
@import './components/vars';
.slider {
  .slick-list {
    padding-top: 20px;
  }
  .slick-container {
    padding: 0 77px;
    position: relative;
    &::before {
      position: absolute;
      top: -130px;
      left: 40px;
      content: '';
      background: url('../img/zerno6.png') no-repeat center;
      width: 150px;
      height: 123px;
      z-index: -1;
    }
  }
  .slick-slide {
    margin: 0 8px;
    height: auto;
  }
  .slide {
    position: relative;
    z-index: 2;
    &-text {
      font-family: $Scada;
      font-size: 14px;
      font-weight: bold;
      height: 35px;
      overflow: hidden;
      margin-bottom: 10px;
      line-height: 18px;
      display: block;
      color: #000;
      text-decoration: none;
    }
    &-date {
      font-family: $OpenSans;
      line-height: 24px;
      color: #373737;
      font-size: 12px;
    }
    .img-box {
      display: block;
      // border-radius: 20%;
      // border: 8px solid #bfd534;
      position: relative;
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      // height: 275px;
      margin-bottom: 20px;
      transition: .3s ease all;
      &:hover {
        transform: scale(1.05);
      }
      img {
        border-radius: 20%;
        border: 8px solid #bfd534;
      }
    }
    .border-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      &>img {
        margin: 0 auto;
        display: block;
        height: 100%;
      }
    }
  }
}

.svg-clip {
  width: 0;
  height: 0;
}
.slick-prev,
.slick-next {
  width: 36px;
  height: 53px;
  text-indent: -9999px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.slick-prev {
  background: url('../img/arrow-left.png') no-repeat center;
  background-size: 100% 100%;
  left: -66px;
}

.slick-next {
  background: url('../img/arrow-right.png') no-repeat center;
  background-size: 100% 100%;
  right: -66px;
}

@media screen and (max-width: 990px) {
  .slider-section {
    padding: 60px 0;
    .slide-text {
      font-size: 20px;
      height: 69px;
    }
  }
  .slick-dots {
    top: 30px;
  }
}
@media screen and (max-width: 800px) {  
  .slider-section .slide .img-box>img {
    border-radius: 15px;
  }
}

@media screen and (max-width: 640px) {
  .slider-section .slick-container:before {
    top: -95px;
    transform: scale(.5);
    left: 0;
  }
}

@media screen and (max-width: 680px) {
  footer,
  .info-section,
  .products-section {
    h2 {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 640px) {
  .slider-section .slide .img-box>img{
    //max-width: 327px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 420px) {
  .slider-section {
    .slick-container {
      padding: 0;
    }
    .slick-next, .slick-prev {
      display: none;
    }
  }
}


.slider-section {
  padding: 112px 0 110px 0;
  h2 {
    font-size: 60px;
    line-height: 1;
    font-family: $Scada;
    font-weight: bold;
    margin-bottom: 55px;
    color: $green-light;
    text-align: center;
  }
  .slick-list {
    padding-top: 20px;
  }
  .slick-container {
    padding: 0 77px;
    position: relative;
    &::before {
      position: absolute;
      top: -130px;
      left: 40px;
      content: '';
      background: url('../img/zerno6.png') no-repeat center;
      width: 150px;
      height: 123px;
      z-index: -1;
    }
  }
  .slick-slide {
    margin: 0 8px;
    height: auto;
  }
  .slide {
    position: relative;
    z-index: 2;
    cursor: pointer;
    &-text {
      font-family: $Scada;
      font-size: 26px;
      font-weight: bold;
      height: 85px;
      overflow: hidden;
      margin-bottom: 17px;
      line-height: 1.15;
    }
    &-date {
      font-family: $OpenSans;
      line-height: 1.5;
      color: #373737;
      font-size: 16px;
    }
    .img-box {
      // border-radius: 20%;
      // border: 8px solid $green-light;
      position: relative;
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      // height: 238px;
      margin-bottom: 35px;
      transition: .3s ease all;
      &:hover {
        transform: scale(1.05);
      }
      img {
        border-radius: 20%;
        border: 8px solid $green-light;
      }
    }
    .border-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      &>img {
        margin: 0 auto;
        display: block;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .slider-section {
    .slick-container {
      padding: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .slick-container {
    &::before {
      display: none;
    }
  }
}