// Media Queries in Sass 3.2
//
// These mixins make media queries a breeze with Sass.
// The media queries from mobile up until desktop all
// trigger at different points along the way
//
// And important point to remember is that and width
// over the portrait width is considered to be part of the
// landscape width. This allows us to capture widths of devices
// that might not fit the dimensions exactly. This means the break
// points are seamless.

$mq-mobile-portrait		: 320px !default;
$mq-mobile-landscape	: 480px !default;
$mq-tablet-portrait		: 768px !default;
$mq-tablet-landscape	: 1024px !default;
$mq-desktop				: 1382px !default;



@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
   @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

// Both portrait and landscape
@mixin mobile-only {
	@media (max-width : $mq-mobile-landscape) {
		@content;
	}
}

// Everything up to and including the portrait width of the phone
// Since it's the smallest query it doesn't need a min
@mixin mobile-portrait-only {
	@media (max-width : $mq-mobile-portrait) {
		@content;
	}
}

// Everything up to and including the mobile portrait
@mixin mobile-portrait-and-below {
	@media (max-width : $mq-mobile-portrait) {
		@content;
	}
}

// Everything above and including the mobile portrait
@mixin mobile-portrait-and-up {
	@media (min-width : $mq-mobile-portrait) {
		@content;
	}
}

// Everthing larger than a portrait mobile up until mobile landscape
@mixin mobile-landscape-only {
	@media only screen and (min-width : $mq-mobile-portrait + 1) and (max-width : $mq-mobile-landscape) {
		@content;
	}
}

// Everything up to and including the mobile landscape width
@mixin mobile-landscape-and-below {
	@media only screen and (max-width : $mq-mobile-landscape) {
		@content;
	}
}

// Everything above and including the mobile landscape width
@mixin mobile-landscape-and-up {
	@media only screen and (min-width : $mq-mobile-portrait + 1) {
		@content;
	}
}

// Both the portrait and landscape width of the tablet
// Larger than a landscape mobile but less than or equal to a landscape tablet
@mixin tablet-only {
	@media only screen and (min-width : $mq-mobile-landscape + 1) and (max-width : $mq-tablet-landscape) {
		@content;
	}
}

// Everything larger than mobile landscape up until the portrait width of the tablet
@mixin tablet-portrait-only {
	@media only screen and (min-width : $mq-mobile-landscape + 1) and (max-width : $mq-tablet-portrait) {
		@content;
	}
}

// Everything below and including the portrait width of the tablet
@mixin tablet-portrait-and-below {
	@media only screen and (max-width : $mq-tablet-portrait) {
		@content;
	}
}

// Everything above and including the portrait width of the tablet
@mixin tablet-portrait-and-up {
	@media only screen and (min-width : $mq-mobile-landscape + 1) {
		@content;
	}
}

// Larger than portrait but less than or equal to the landscape width
@mixin tablet-landscape-only {
	@media only screen and (min-width : $mq-tablet-portrait + 1) and (max-width : $mq-tablet-landscape) {
		@content;
	}
}

// Up to and including the tablet landscape
@mixin tablet-landscape-and-below {
	@media only screen and (max-width : $mq-tablet-landscape) {
		@content;
	}
}

// Everything larger than portrait tablet
@mixin tablet-landscape-and-up {
	@media only screen and (min-width : $mq-tablet-portrait + 1) {
		@content;
	}
}

// Everything larger than a landscape tablet
@mixin desktop-and-up {
	@media only screen and (min-width : $mq-tablet-landscape + 1) {
		@content;
	}
}

// Everything below and including the desktop
@mixin desktop-and-below {
	@media only screen and (max-width : $mq-desktop) {
		@content;
	}
}

// Everything larger than a landscape tablet but less than or equal to the desktop
@mixin desktop-only {
	@media only screen and (min-width : $mq-tablet-landscape + 1) and (max-width : $mq-desktop) {
		@content;
	}
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
		@content;
	}
}



@mixin super-rectangle ($width, $height, $color){
	$width: trim($width);
	$height: trim($height);
	$color: replace(''+$color+'', '#', '%23');
	$halfWidth: $width / 2;
	$halfHeight: $height / 2;
	background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cpath fill='"+$color+"' stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/svg%3E");
	background-size: #{$width + 'px'} #{$height + 'px'};
	background-repeat: no-repeat;
}
@function trim($number) {
	@if type-of($number)=='number' and not unitless($number) {
		@return $number / ($number * 0+1);
	}
	@return $number;
}

@function replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@return if($index,
					str-slice($string, 1, $index - 1) + $replace + replace(str-slice($string, $index + str-length($search)), $search, $replace),
					$string);
}

@mixin shadow-plain-right( $w, $h, $c){
	position: relative;
	&:before{
		@include super-rectangle( $w, $h, #9ccb3b);
		position: absolute;
		width: $w;
		height: $h;
		z-index: -1;
		content: "";
		left: 3px;
		top: 3px;
	}
}
@mixin shadow-plain-left( $w, $h, $c){
	position: relative;
	&:after{
		@include super-rectangle( $w, $h, #9ccb3b);
		position: absolute;
		width: $w;
		height: $h;
		z-index: -1;
		content: "";
		left: -3px;
		top: -3px;
	}
}