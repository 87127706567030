@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  font-family: $Scada;
  transition: 0.3s ease all;
  .dropdown-title a {
    color: #fff;
  }
  .dropdown-title a:hover {
    text-decoration: none;
  }
  &.top {
    &.js-sticky {
      top: -100%;
    }
  }
  &.bot {
    &.js-sticky {
      top: 0;
    }
  }
  &.js-sticky {
    background: rgba(255, 255, 255, 0.8);
    color: $black;
    .container {
      padding-left: 15px;
    }
    a,
    .dropdown-title {
      color: #000;
    }
    .header-nav-item a:before {
      background: #000;
    }
  }
  .menu-btn {
    display: none;
  }
  .container {
    padding-left: 250px;
    transition: 0.6s ease all;
  }
  &-nav {
    text-align: center;
    padding: 23px 0;
    display: inline-block;
    vertical-align: middle;
  }
  &-nav-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px;
    &>a {
      color: #fff;
      font-weight: 700;
      font-size: 22px;
      line-height: 1;
      text-decoration: none;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: 0.3s ease all;
      }
    }
    &.js-active {
      a:before {
        width: 100%;
      }
    }
  }
  &-dropdown {
    margin-top: 25px;
    float: right;
    font-size: 18px;
    padding-top: 5px;
  }
}

.dropdown {
  display: inline-block;
  cursor: pointer;
  position: relative;
  &-title {
    color: #fff;
    font-weight: 400;
  }
  &-padding-top {
    top: 100%;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
    height: 0;
    left: -18px;
    width: 46px;
    position: absolute;
  }
  &-list {
    overflow: hidden;
    background: #9ccb3b;
    border-radius: 5px;
    width: 46px;
  }
  &-list-item {
    transition: .3s ease all;
    a {
      transition: .3s ease all;
      padding: 5px 10px;
      display: block;
      color: $white;
      font-weight: 400;
      text-decoration: none;
    }
    &:hover, &.active {
      background: #fff;
      a {
        color: #9ccb3b;
      }
    }
  }
  &.js-active {
    .dropdown-padding-top{
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}

body.hover-on {
  .dropdown:hover {
    .dropdown-padding-top {
      opacity: 1;
      height: auto;
      visibility: visible;
    }
  }
  header {
    a:hover {
      &:before {
        width: 100%;
      }
    }
  }
}

.preview-section {
  padding-top: 35px;
  min-height: 650px;
  background-image: url('../img/main-bg.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  .container {
    position: relative;
    z-index: 2;
  }
  .logo {
    max-width: 188px;
    max-height: 188px;
  }
  .bottom-content {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 220px;
    .container {
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        width: 148px;
        height: 113px;
        background: url('../img/zerno5.png') no-repeat center center;
        left: -3.3%;
        bottom: -22px;
      }
      &:after {
        width: 161px;
        height: 130px;
        right: -6.5%;
        bottom: -55px;
        background: url('../img/zerno3.png') no-repeat center center;
      }
    }
  }
  .sun-image {
    position: absolute;
    top: 0;
    right: 80px;
  }
  .bg-image {
    max-width: none;
    position: absolute;
    max-width: none;
    z-index: -1;
    bottom: -20px;
    left: -5%;
    width: 110%;
    height: 100%;
  }
  .stroke-text {
    font-family: $Scada;
    max-width: 750px;
    margin: 0 auto;
    font-weight: 700;
    transform: rotate(-5deg);
    font-size: 89px;
    line-height: 1;
    color: #fff;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.7);
    -webkit-text-stroke: 3px #9ccb3b;
    margin-bottom: 22px;
    position: relative;
    bottom: 30px;
  }
}

.description-section {
  text-align: center;
  color: #6f902a;
  font-size: 21px;
  line-height: 36px;
  font-weight: 400;
  margin: 0 auto 20px;
}

.go-top {
  display: none;
  position: fixed;
  right: 50px;
  bottom: 100px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  .arrow {
    display: inline-block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: transparent;
    text-indent: -9999px;
    border-top: 2px solid #bfbfbf;
    border-left: 2px solid #bfbfbf;
    transition: all 250ms ease-in-out;
    text-decoration: none;
    color: transparent;
  }
  .arrow.up {
    transform: rotate(45deg);
    left: 16px;
    top: 18px;
  }
}

footer {
  padding: 108px 0 38px;
  background: url('../img/footer-bg.png') no-repeat center;
  background-size: cover;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    right: 10%;
    top: -26px;
    width: 71px;
    height: 53px;
    background: url('../img/zerno4.png');
    background-size: cover;
  }
  h2 {
    font-family: $Scada;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 59px;
  }
  .box {
    background: url('../img/bg-box.png') no-repeat center;
    background-size: contain;
    max-width: 802px;
    margin: 0 auto 62px;
    text-align: center;
    padding: 42px 0 71px;
    color: #fff;
  }
  .text {
    max-width: 516px;
    margin: 0 auto;
    p {
      font-family: $OpenSans;
      font-size: 21px;
      line-height: 1.38;
      text-align: left;
      overflow: hidden;
      margin-bottom: 40px;
    }
    i {
      margin-right: 14px;
      float: left;
    }
    .info {
      white-space: nowrap;
      li {
        font-family: $OpenSans;
        font-size: 26px;
        line-height: 1.38;
        list-style: none;
        display: inline-block;
        vertical-align: middle;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
  a {
    display: inline-block;
    color: #fff;
  }
  .links {
    text-align: center;
    a {
      margin: 0 15px;
    }
  }
}

@media screen and (max-width: 1130px) {
  .header {
    &-nav-item {
      margin: 0 15px;
    }
    .container {
      padding-left: 177px;
    }
  }
  .preview-section {
    min-height: 470px;
    .sun-image {
      display: none;
    }
    .logo {
      max-width: 100px;
      max-height: 100px;
    }
    .stroke-text {
      max-width: 461px; // margin-right: 70px;
      font-size: 56px;
    }
    .bg-image {
      height: 50%;
    }
    .bottom-content {
      .container {
        &:before,
        &:after {
          transform: scale(.7);
        }
        &:before {
          bottom: -114px;
        }
        &:after {
          bottom: -100px
        }
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .header {
    &-nav-item {
      a {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &.is-menu-open {
      .hamburger--squeeze.is-active {
        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          background: #000;
        }
      }
      .header-dropdown {
        display: none;
      }
      &.js-sticky {
        background-color: rgba(0, 0, 0, 0);
        .header-dropdown {
          display: none;
        }
      }
      .header-nav {
        left: 0;
        &-item {
          text-align: right;
          display: block;
          a {
            display: inline-block;
            padding: 5px 0;
            color: #000;
            &:before {
              background: #000;
            }
          }
        }
      }
    }
    &.js-sticky {
      .hamburger-inner,
      .hamburger-inner:after,
      .hamburger-inner:before {
        background: #000;
      }
    }
    .menu-btn {
      display: inline-block;
      z-index: 3;
      position: relative;
    }
    .container {
      padding: 0 15px;
    }
  }
  .preview-section {
    .logo {
      display: none;
    }
  }
  .header-dropdown {
    margin-top: 16px;
    font-size: 16px;
  }
  .header-nav {
    position: absolute;
    top: 0;
    left: -100%;
    padding: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 10px 10px 50px;
    z-index: 2;
  }
}

@media screen and (max-width: 770px) {
  footer {
    padding: 60px 0 30px;
    h2 {
      font-size: 50px;
      margin-bottom: 25px;
    }
    .text {
      p {
        font-size: 20px;
      }
      .info li {
        margin: 0 !important;
        width: 50%;
        float: left;
        text-align: left;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  footer {
    .box {
      background-size: cover;
      padding: 42px 10px 71px;
      border-radius: 20%;
      .text {
        p {
          margin: 0 0 15px 0;
        }
        .info li {
          clear: both;
          display: block;
          float: none;
          width: 100%;
          margin-bottom: 15px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .preview-section {
    min-height: 400px;
    .stroke-text {
      max-width: 282px;
      font-size: 43px;
    }
  }
}

@media screen and (min-width: 769px) {
  .header {
    &-nav-item {
      &.link-stock-btn {
        background-color: #ff1414;
        padding: 5px 20px;
        border-radius: 30px;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &-nav-item {
      &.link-stock-btn a {
        color: #ff1414!important;
      }
    }
  }
}