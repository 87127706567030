@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.prices-section {
  background: url('../img/price-main-bg.png') no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh;
  .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .wrapper {
    padding: 36px 10% 50px;
    &.mechanics-wrapper {
      background: url('../img/price-bg-1.png') no-repeat center;
      background-size: contain;
      margin-bottom: 22px;
    }
    &.present-wrapper {
      background: url('../img/price-bg-2.png') no-repeat center;
      background-size: contain;
      margin-bottom: 54px;
    }
    h3 {
      font-size: 34px;
      line-height: 1;
      color: #9ccb3b;
      margin-bottom: 19px;
      font-family: $Scada;
      font-weight: bold;
      text-align: center;
    }
  }
  h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: $Scada;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  h2 {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    max-width: 650px;
    margin: 0 auto 26px;
  }
  .wrapper {
    &.mechanics-wrapper {
      ol {
        list-style: none;
        counter-reset: li;
        li {
          font-size: 16px;
          line-height: 38px;
          color: #373737;
          margin-bottom: 5px;
          counter-increment: li;
          position: relative;
          &:before {
            content: counter(li);
            color: #9ccb3b;
            display: inline-block;
            width: 1em;
            margin-left: -1em
          }
          /*
          &:after {
            position: absolute;
            left: -7px;
            width: 1px;
            height: 1px;
            content: '.';
            color: #9ccb3b;
          }
          */
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    &.present-wrapper {
      .wrap {
        width: 32%;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 25px;
        &:last-of-type {
          width: auto;
          max-width: 512px;
          display: block;
          margin: 0 auto;
          img {
            float: left;
            margin-right: 18px;
          }
          .inner-wrap {
            padding-top: 13px;
            text-align: left;
          }
          .text {
            text-align: left;
            font-size: 16px;
            line-height: 25px;
          }
          .text-head {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 15px;
          }
        }
      }
      img {
        height: 116px;
        display: block;
        margin: 0 auto 10px;
      }
      .text {
        font-size: 16px;
        line-height: 38px;
        color: #373737;
        text-align: center;
        span {
          color: #9ccb3b;
        }
      }
    }
  }
}

.additional-text {
  max-width: 525px;
  margin: 0 auto;
  text-align: center;
  font-family: $Scada;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 875px) {
  .prices-section {
    .container {
      padding-right: 0;
      padding-left: 0;
      max-width: none;
    }
    .wrapper {
      &.mechanics-wrapper,
      &.present-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        background: #fff;
      }
      &.mechanics-wrapper {
        ol {
          padding-left: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 715px) {
  .prices-section{
    h1 {
      font-size: 30px;
      line-height: 1;
    }
    h2 {
      font-size: 18px;
      line-height: 26px;
      max-width: 420px;
    }
    .wrapper {
      padding: 15px 10px 30px;
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
      &.mechanics-wrapper {
        ol {
          li {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
      &.present-wrapper {
        .wrap {
          display: block;
          float: none;
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .prices-section .wrapper.present-wrapper {
    .wrap:last-of-type {
      text-align: center;
      img {
        float: none;
        margin: 0 auto;
      }
      .inner-wrap {
        text-align: center;
        max-width: 300px;
        margin: 0 auto;
      }
      .text-head {
        text-align: center;
      }
    }
  }
}