@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*--------------GLOBAL RESET-----------------*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*:-moz-placeholder,
*::-moz-placeholder,
*:-ms-input-placeholder,
*::-webkit-input-placeholder {
  color: #cdcbcb;
  opacity: 1;
  text-align: center; }

*:focus::-webkit-input-placeholder,
*:focus:-ms-input-placeholder,
*:focus::-moz-placeholder,
*:focus:-moz-placeholder {
  color: rgba(0, 0, 0, 0); }

.slick-slider *,
.select2 * {
  outline: 0; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn,
em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, menu, nav, output, ruby, section,
summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, menu, nav,
section, main {
  display: block; }

img {
  max-width: 100%;
  height: auto; }

ul.reset {
  list-style: none; }

ul.circle-list {
  list-style: none; }
  ul.circle-list li {
    padding-left: 13px;
    position: relative;
    line-height: 1.2; }
    ul.circle-list li:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      overflow: hidden;
      background: red; }

.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0; }

button {
  outline: 0;
  border: 0;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  cursor: pointer; }

input, textarea {
  outline: 0; }

input::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

/*--------------END GLOBAL RESET-----------------*/
/*--------------CUSTOM RESET-----------------*/
html, body {
  font-size: 14px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #373737;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.content-wrapper {
  flex: 1 auto;
  overflow: hidden; }

a {
  text-decoration: none;
  transition: .3s ease all; }
  a:hover {
    text-decoration: underline; }

a[href^="tel:"]:hover {
  text-decoration: none; }

.container {
  max-width: 1220px;
  padding: 0 15px;
  margin: 0 auto; }

.hide {
  display: none; }

.left {
  float: left; }

.right {
  float: right; }

section.promo-section {
  min-height: 100vh; }

/*-----------END CUSTOM RESET----------------*/
.field {
  width: 100%; }
  .field input {
    padding: 0 12px;
    line-height: 46px;
    height: 40px; }
  .field textarea {
    line-height: 1.2;
    height: 109px;
    padding: 9px 12px; }
  .field input,
  .field textarea {
    display: block;
    width: 100%;
    border-radius: 3px;
    text-align: center;
    border: 0;
    border-radius: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-weight: 400;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000; }
    .field input.invalid,
    .field textarea.invalid {
      border-color: #e64f28; }
    .field input.valid,
    .field textarea.valid {
      border-color: green; }
  .field label.error {
    font-size: 14px;
    position: absolute;
    top: 100%;
    left: 0;
    color: #e64f28; }

.button,
a.button {
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  font-size: 18px;
  padding: 0 36px;
  color: #fff;
  font-weight: normal;
  border-radius: 4px;
  background-color: #e64f28;
  height: 60px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  background-color: #e64f28; }

ul.circle-list {
  list-style: none; }
  ul.circle-list > li {
    padding-left: 19px;
    position: relative;
    line-height: 1.5; }
    ul.circle-list > li:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      overflow: hidden;
      background: #e64f28; }

.icon-arrow {
  border: solid #cacaca;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px; }
  .icon-arrow--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .icon-arrow--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .icon-arrow--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  .icon-arrow--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.flip-clock-divider {
  display: none; }

.flip-clock-wrapper {
  width: 219px;
  margin: 0 auto;
  text-align: center; }

.flip-clock-wrapper ul {
  width: 24px;
  height: 48px;
  line-height: 48px;
  font-size: 40px;
  margin: 0;
  border-radius: 4px;
  margin-right: 2px; }
  .flip-clock-wrapper ul:nth-of-type(8), .flip-clock-wrapper ul:nth-of-type(6), .flip-clock-wrapper ul:nth-of-type(4), .flip-clock-wrapper ul:nth-of-type(2) {
    margin-right: 6px; }
    .flip-clock-wrapper ul:nth-of-type(8)::before, .flip-clock-wrapper ul:nth-of-type(6)::before, .flip-clock-wrapper ul:nth-of-type(4)::before, .flip-clock-wrapper ul:nth-of-type(2)::before {
      content: "qwe";
      position: absolute;
      color: #e64f28;
      left: -11px;
      font-size: 10px;
      top: 33px;
      z-index: 101; }
  .flip-clock-wrapper ul:last-of-type {
    margin-right: 0; }
  .flip-clock-wrapper ul li {
    font-size: 40px;
    line-height: 48px; }
  .flip-clock-wrapper ul:nth-of-type(8):before {
    content: "СЕК"; }
  .flip-clock-wrapper ul:nth-of-type(6):before {
    content: "ХВ";
    left: -8px; }
  .flip-clock-wrapper ul:nth-of-type(4):before {
    content: "ГОД"; }
  .flip-clock-wrapper ul:nth-of-type(2):before {
    content: "ДНIВ"; }

.flip-clock-wrapper ul li a div {
  font-size: 40px; }

.flip-clock-wrapper ul li a div div.inn {
  font-size: 40px;
  font-weight: normal; }

.flip-clock-wrapper .flip:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #f8e534;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 101; }

.field-checkbox input[type='checkbox'] {
  display: none !important; }

.field-checkbox label {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 26px;
  color: #222;
  padding-left: 23px !important;
  margin: 0 !important;
  position: relative; }
  .field-checkbox label:after {
    background: #e64f28;
    width: 14px;
    height: 14px;
    display: none;
    content: '';
    position: absolute;
    z-index: 2;
    left: 1px;
    top: -13px; }
  .field-checkbox label:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    z-index: 1;
    border: 1px solid #fff;
    background: none; }

.field-checkbox input[type=checkbox]:checked + label:after {
  display: block; }

.tabs-container {
  position: relative; }

.tabs-list {
  margin-bottom: 18px; }

.tabs-list-item {
  cursor: pointer;
  margin: 0 13px;
  width: 172px;
  height: 57px;
  line-height: 55px;
  background: url("../img/tab-bg.png") no-repeat center;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  transition: 0.3s ease all;
  text-align: center;
  font-size: 18px; }
  .tabs-list-item.js-active {
    background: url("../img/tab-bg2.png") no-repeat center;
    background-size: 100% 100%;
    color: #9ccb3b; }

.tabs-container-tab {
  display: none; }
  .tabs-container-tab.js-active {
    display: block; }

.pagination {
  text-align: center;
  clear: both; }
  .pagination ul li {
    margin: 0 3px;
    display: inline-block; }
    .pagination ul li a, .pagination ul li span {
      font-size: 18px;
      line-height: 30px;
      color: #959595;
      text-decoration: none;
      font-weight: bold; }
    .pagination ul li.active a, .pagination ul li.active span {
      color: #e64f28; }

.triangl-btm {
  position: relative;
  z-index: 2; }

.triangl-btm:after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: calc(100% - 10px);
  right: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border-bottom: 2px solid #e3b87d;
  border-left: 2px solid #e3b87d;
  transform: rotate(-45deg); }

ul.circle-list {
  list-style: none; }
  ul.circle-list > li {
    padding-left: 19px;
    position: relative;
    line-height: 1.5; }
    ul.circle-list > li:before {
      position: absolute;
      top: 5px;
      left: 0;
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      overflow: hidden;
      background: firebrick; }

.icon-arrow {
  border: solid #cacaca;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px; }
  .icon-arrow--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .icon-arrow--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .icon-arrow--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  .icon-arrow--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slider-section .slick-next,
.slider-section .slick-prev {
  position: absolute;
  top: 107px;
  margin: auto;
  width: 36px;
  height: 53px;
  left: -73px;
  z-index: 5;
  text-indent: -9999px;
  background: url("../img/arrow-left-2.png") no-repeat center !important; }

.slider-section .slick-next {
  left: inherit;
  right: -73px;
  background-image: url("../img/arrow-right-2.png") !important; }

.slider-section .slick-dots {
  top: 65px;
  text-align: center;
  position: relative; }
  .slider-section .slick-dots > li {
    display: inline-block;
    margin: 0 6px;
    width: auto;
    height: auto; }
    .slider-section .slick-dots > li button {
      border-radius: 50%;
      text-indent: -9999px;
      width: 21px;
      height: 21px;
      background-color: #cacaca;
      transition: .3s ease all; }
    .slider-section .slick-dots > li.slick-active button, .slider-section .slick-dots > li:hover button {
      background: #bfd434; }

.news-section .slick-next,
.news-section .slick-prev {
  position: absolute;
  top: 40px;
  margin: auto;
  width: 36px;
  height: 53px;
  left: -73px;
  z-index: 5;
  text-indent: -9999px;
  background: url("../img/arrow-left-2.png") no-repeat center !important; }

.news-section .slick-next {
  left: inherit;
  right: -73px;
  background-image: url("../img/arrow-right-2.png") !important; }

.news-section .slick-dots {
  top: 70px;
  text-align: center;
  position: relative; }
  .news-section .slick-dots > li {
    display: inline-block;
    margin: 0 6px;
    width: auto;
    height: auto; }
    .news-section .slick-dots > li button {
      border-radius: 50%;
      text-indent: -9999px;
      width: 21px;
      height: 21px;
      background-color: #cacaca;
      transition: .3s ease all; }
    .news-section .slick-dots > li.slick-active button, .news-section .slick-dots > li:hover button {
      background: #bfd434; }

i.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff; }
  i.icon.point {
    width: 23px;
    height: 31px;
    background: url("../img/icons/point.svg") no-repeat center; }
  i.icon.phone {
    width: 30px;
    height: 30px;
    background: url("../img/icons/phone.svg") no-repeat center; }
  i.icon.message {
    width: 27px;
    height: 28px;
    background: url("../img/icons/message.svg") no-repeat center; }
  i.icon.instagram {
    width: 50px;
    height: 50px;
    background: url("../img/icons/instagram.svg") no-repeat center; }
  i.icon.facebook {
    width: 47px;
    height: 47px;
    background: url("../img/icons/facebook.svg") no-repeat center; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.circle {
  position: relative;
  z-index: 10;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  transform: rotate(-12deg);
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 30px;
  animation-timing-function: cubic-bezier(0, 0.3, 1, 0.7);
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite; }
  .circle img {
    z-index: -1;
    display: block;
    position: relative;
    transform: rotate(12deg); }
  .circle:last-of-type {
    margin-right: 0; }

@keyframes example {
  from {
    top: -600%; }
  to {
    top: 120%; } }

.circle:before, .circle:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%; }

.circle:before {
  width: 140%;
  top: inherit;
  left: -20%;
  opacity: 0.6;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 40%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0));
  transform: rotate(-20deg); }

.circle:after {
  width: 100%;
  top: 0;
  border-radius: 50%;
  box-shadow: inset -5px -15px 40px rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(35% 25%, closest-corner, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)); }

#user_details {
  text-align: center; }
  #user_details label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    margin-top: 10px; }
  #user_details .buttons {
    padding-top: 15px; }
    #user_details .buttons .btn {
      font-family: Open Sans,sans-serif;
      display: inline-block;
      font-size: 18px;
      padding: 0 36px;
      color: #fff;
      font-weight: 400;
      height: 50px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      border-radius: 30px;
      background-color: #e64f28;
      border: none;
      cursor: pointer; }
  #user_details .text-danger {
    color: #e64f28; }
  #user_details input {
    width: 100%;
    text-align: center;
    border-radius: 20px;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    border: 1px solid #9ccb3b;
    padding: 0 12px;
    height: 40px;
    max-width: 300px; }

.navigation {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../img/menu-bg.png") no-repeat center;
  background-size: cover;
  z-index: 102;
  display: none; }
  .navigation.js-active {
    display: block; }
  .navigation .candy-img {
    position: absolute;
    z-index: 1;
    width: 844px;
    left: 0;
    top: 0; }
  .navigation .nav-wrap {
    position: relative;
    z-index: 2;
    padding-top: 28px;
    padding-right: 43px;
    width: 100%;
    text-align: right; }
  .navigation .top-btn,
  .navigation .spliter {
    font-family: "Scada", sans-serif;
    font-size: 26px;
    color: #fff;
    font-weight: normal;
    position: relative;
    top: -3px; }
  .navigation .spliter {
    margin: 0 4px; }
  .navigation .menu-btn2-closer {
    width: 24px;
    height: 24px;
    margin-left: 77px;
    background: url("../img/close-red.svg") no-repeat center;
    background-size: 100% 100%; }
  .navigation .nav-list {
    margin-top: 90px;
    padding-right: 107px;
    list-style: none; }
    .navigation .nav-list li {
      margin-bottom: 44px; }
      .navigation .nav-list li:last-of-type {
        margin-bottom: 0; }
      .navigation .nav-list li a {
        font-family: "Scada", sans-serif;
        font-size: 34px;
        color: #fff;
        line-height: 1;
        display: inline; }

.pages-menu .menu-btn2 {
  width: 33px;
  height: 23px;
  position: absolute;
  right: 41px;
  top: 29px; }

@media screen and (max-width: 1215px) {
  .navigation .candy-img {
    width: calc(100% - 400px); } }

@media screen and (max-width: 895px) {
  .navigation .candy-img {
    display: none; } }

@media screen and (max-width: 715px) {
  .navigation .nav-wrap {
    padding: 10px 15px; }
  .pages-menu .menu-btn2 {
    top: 10px;
    right: 15px; } }

@media screen and (max-width: 1200px) {
  .navigation .nav-list {
    padding-right: 0;
    margin-top: 30px; }
    .navigation .nav-list li {
      margin-bottom: 20px; } }

@media screen and (max-width: 425px) {
  .navigation .top-btn,
  .navigation .spliter {
    margin-right: 0;
    float: left;
    font-size: 20px;
    top: 0; }
  .navigation .spliter {
    top: 2px;
    margin: 0 2px; }
  .navigation .menu-btn2-closer {
    margin-left: 10px; }
  .navigation .nav-list {
    padding-right: 0;
    margin-top: 15px; }
    .navigation .nav-list li {
      margin-bottom: 15px; }
      .navigation .nav-list li a {
        font-size: 20px; } }

.overlay {
  background: rgba(255, 255, 255, 0.4);
  position: fixed;
  z-index: -1;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  .overlay.active {
    visibility: visible;
    z-index: 111; }

.modal {
  z-index: 12;
  top: -1000px;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  transition: .3s ease all;
  visibility: hidden;
  width: auto;
  max-width: 574px;
  border-radius: 5px;
  background: #000;
  z-index: 122; }
  .modal.active {
    visibility: visible; }
  .modal-closer {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    text-align: center; }
    .modal-closer::before, .modal-closer:after {
      position: absolute;
      content: '';
      top: 7px;
      border-radius: 4px;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      transform: rotate(45deg); }
    .modal-closer:after {
      transform: rotate(135deg); }
  .modal-head {
    padding: 14px 0 0 0;
    text-align: center; }
  .modal-body {
    overflow: hidden;
    max-width: 710px;
    padding: 0 10px 42px;
    margin: 0 auto; }
    .modal-body .row {
      margin-bottom: 20px; }
      .modal-body .row:last-of-type {
        margin-bottom: 0; }

.registration-modal, .login-modal, .forgotten-modal, .recall-modal, .stock-modal {
  background: url("../img/reg-modal-bg.jpg") no-repeat center;
  background-size: cover; }
  .registration-modal .inner, .login-modal .inner, .forgotten-modal .inner, .recall-modal .inner, .stock-modal .inner {
    max-width: 331px;
    margin: 0 auto; }
  .registration-modal .logo, .login-modal .logo, .forgotten-modal .logo, .recall-modal .logo, .stock-modal .logo {
    width: 89px;
    height: 89px;
    margin: 0 auto 12px; }
  .registration-modal h2, .login-modal h2, .forgotten-modal h2, .recall-modal h2, .stock-modal h2 {
    font-family: "Scada", sans-serif;
    font-size: 28px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 12px;
    color: #fff; }
  .registration-modal .field, .login-modal .field, .forgotten-modal .field, .recall-modal .field, .stock-modal .field {
    margin-bottom: 20px;
    position: relative; }
  .registration-modal .captch-wrap, .login-modal .captch-wrap, .forgotten-modal .captch-wrap, .recall-modal .captch-wrap, .stock-modal .captch-wrap {
    height: 75px;
    background: #fff;
    margin-bottom: 20px; }
  .registration-modal .row, .login-modal .row, .forgotten-modal .row, .recall-modal .row, .stock-modal .row {
    margin-bottom: 20px; }
    .registration-modal .row:last-of-type, .login-modal .row:last-of-type, .forgotten-modal .row:last-of-type, .recall-modal .row:last-of-type, .stock-modal .row:last-of-type {
      margin-bottom: 0; }
  .registration-modal span, .login-modal span, .forgotten-modal span, .recall-modal span, .stock-modal span {
    font-family: "Open Sans", sans-serif;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    display: block;
    text-align: center;
    margin: 0 auto 15px; }
  .registration-modal button.button, .registration-modal a.button, .login-modal button.button, .login-modal a.button, .forgotten-modal button.button, .forgotten-modal a.button, .recall-modal button.button, .recall-modal a.button, .stock-modal button.button, .stock-modal a.button {
    display: block;
    width: 100%;
    max-width: none;
    padding: 0; }
  .registration-modal label, .login-modal label, .forgotten-modal label, .recall-modal label, .stock-modal label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 18px; }
    .registration-modal label p, .login-modal label p, .forgotten-modal label p, .recall-modal label p, .stock-modal label p {
      margin: -20px 0 -20px 24px;
      color: #fff;
      overflow: hidden; }
    .registration-modal label a, .login-modal label a, .forgotten-modal label a, .recall-modal label a, .stock-modal label a {
      color: #fff;
      text-decoration: underline; }
      .registration-modal label a:hover, .login-modal label a:hover, .forgotten-modal label a:hover, .recall-modal label a:hover, .stock-modal label a:hover {
        text-decoration: none; }
  .registration-modal .field-checkbox, .login-modal .field-checkbox, .forgotten-modal .field-checkbox, .recall-modal .field-checkbox, .stock-modal .field-checkbox {
    float: left; }
  .registration-modal .enter-btn, .login-modal .enter-btn, .forgotten-modal .enter-btn, .recall-modal .enter-btn, .stock-modal .enter-btn {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold; }
  .registration-modal a.link, .login-modal a.link, .forgotten-modal a.link, .recall-modal a.link, .stock-modal a.link {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0 7px; }
  .registration-modal .fb-btn, .login-modal .fb-btn, .forgotten-modal .fb-btn, .recall-modal .fb-btn, .stock-modal .fb-btn {
    margin-right: 13px; }
  .registration-modal a.button.reg-btn, .registration-modal a.button.fb-btn, .login-modal a.button.reg-btn, .login-modal a.button.fb-btn, .forgotten-modal a.button.reg-btn, .forgotten-modal a.button.fb-btn, .recall-modal a.button.reg-btn, .recall-modal a.button.fb-btn, .stock-modal a.button.reg-btn, .stock-modal a.button.fb-btn {
    width: auto;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 19px;
    margin-bottom: 10px; }

.stock-h3 {
  color: #fff;
  padding-bottom: 10px;
  max-width: 1060px;
  margin: 0 auto; }

.stock-modal {
  margin-bottom: 30px; }

.login-modal .moda-body {
  padding-bottom: 32px; }

.login-modal .inner {
  max-width: 399px;
  text-align: center; }

.form-success {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px; }

.form-error {
  color: #e64f28;
  font-size: 16px;
  margin-bottom: 5px; }

.slider .slick-list {
  padding-top: 20px; }

.slider .slick-container {
  padding: 0 77px;
  position: relative; }
  .slider .slick-container::before {
    position: absolute;
    top: -130px;
    left: 40px;
    content: '';
    background: url("../img/zerno6.png") no-repeat center;
    width: 150px;
    height: 123px;
    z-index: -1; }

.slider .slick-slide {
  margin: 0 8px;
  height: auto; }

.slider .slide {
  position: relative;
  z-index: 2; }
  .slider .slide-text {
    font-family: "Scada", sans-serif;
    font-size: 14px;
    font-weight: bold;
    height: 35px;
    overflow: hidden;
    margin-bottom: 10px;
    line-height: 18px;
    display: block;
    color: #000;
    text-decoration: none; }
  .slider .slide-date {
    font-family: "Open Sans", sans-serif;
    line-height: 24px;
    color: #373737;
    font-size: 12px; }
  .slider .slide .img-box {
    display: block;
    position: relative;
    margin-bottom: 20px;
    transition: .3s ease all; }
    .slider .slide .img-box:hover {
      transform: scale(1.05); }
    .slider .slide .img-box img {
      border-radius: 20%;
      border: 8px solid #bfd534; }
  .slider .slide .border-box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0; }
    .slider .slide .border-box > img {
      margin: 0 auto;
      display: block;
      height: 100%; }

.svg-clip {
  width: 0;
  height: 0; }

.slick-prev,
.slick-next {
  width: 36px;
  height: 53px;
  text-indent: -9999px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto; }

.slick-prev {
  background: url("../img/arrow-left.png") no-repeat center;
  background-size: 100% 100%;
  left: -66px; }

.slick-next {
  background: url("../img/arrow-right.png") no-repeat center;
  background-size: 100% 100%;
  right: -66px; }

@media screen and (max-width: 990px) {
  .slider-section {
    padding: 60px 0; }
    .slider-section .slide-text {
      font-size: 20px;
      height: 69px; }
  .slick-dots {
    top: 30px; } }

@media screen and (max-width: 800px) {
  .slider-section .slide .img-box > img {
    border-radius: 15px; } }

@media screen and (max-width: 640px) {
  .slider-section .slick-container:before {
    top: -95px;
    transform: scale(0.5);
    left: 0; } }

@media screen and (max-width: 680px) {
  footer h2,
  .info-section h2,
  .products-section h2 {
    font-size: 40px; } }

@media screen and (max-width: 640px) {
  .slider-section .slide .img-box > img {
    margin: 0 auto; } }

@media screen and (max-width: 420px) {
  .slider-section .slick-container {
    padding: 0; }
  .slider-section .slick-next, .slider-section .slick-prev {
    display: none; } }

.slider-section {
  padding: 112px 0 110px 0; }
  .slider-section h2 {
    font-size: 60px;
    line-height: 1;
    font-family: "Scada", sans-serif;
    font-weight: bold;
    margin-bottom: 55px;
    color: #9ccb3b;
    text-align: center; }
  .slider-section .slick-list {
    padding-top: 20px; }
  .slider-section .slick-container {
    padding: 0 77px;
    position: relative; }
    .slider-section .slick-container::before {
      position: absolute;
      top: -130px;
      left: 40px;
      content: '';
      background: url("../img/zerno6.png") no-repeat center;
      width: 150px;
      height: 123px;
      z-index: -1; }
  .slider-section .slick-slide {
    margin: 0 8px;
    height: auto; }
  .slider-section .slide {
    position: relative;
    z-index: 2;
    cursor: pointer; }
    .slider-section .slide-text {
      font-family: "Scada", sans-serif;
      font-size: 26px;
      font-weight: bold;
      height: 85px;
      overflow: hidden;
      margin-bottom: 17px;
      line-height: 1.15; }
    .slider-section .slide-date {
      font-family: "Open Sans", sans-serif;
      line-height: 1.5;
      color: #373737;
      font-size: 16px; }
    .slider-section .slide .img-box {
      position: relative;
      margin-bottom: 35px;
      transition: .3s ease all; }
      .slider-section .slide .img-box:hover {
        transform: scale(1.05); }
      .slider-section .slide .img-box img {
        border-radius: 20%;
        border: 8px solid #9ccb3b; }
    .slider-section .slide .border-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0; }
      .slider-section .slide .border-box > img {
        margin: 0 auto;
        display: block;
        height: 100%; }

@media screen and (max-width: 420px) {
  .slider-section .slick-container {
    padding: 0; } }

@media screen and (max-width: 480px) {
  .slick-container::before {
    display: none; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  font-family: "Scada", sans-serif;
  transition: 0.3s ease all; }
  .header .dropdown-title a {
    color: #fff; }
  .header .dropdown-title a:hover {
    text-decoration: none; }
  .header.top.js-sticky {
    top: -100%; }
  .header.bot.js-sticky {
    top: 0; }
  .header.js-sticky {
    background: rgba(255, 255, 255, 0.8);
    color: 900; }
    .header.js-sticky .container {
      padding-left: 15px; }
    .header.js-sticky a,
    .header.js-sticky .dropdown-title {
      color: #000; }
    .header.js-sticky .header-nav-item a:before {
      background: #000; }
  .header .menu-btn {
    display: none; }
  .header .container {
    padding-left: 250px;
    transition: 0.6s ease all; }
  .header-nav {
    text-align: center;
    padding: 23px 0;
    display: inline-block;
    vertical-align: middle; }
  .header-nav-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px; }
    .header-nav-item > a {
      color: #fff;
      font-weight: 700;
      font-size: 22px;
      line-height: 1;
      text-decoration: none;
      position: relative; }
      .header-nav-item > a:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: 0.3s ease all; }
    .header-nav-item.js-active a:before {
      width: 100%; }
  .header-dropdown {
    margin-top: 25px;
    float: right;
    font-size: 18px;
    padding-top: 5px; }

.dropdown {
  display: inline-block;
  cursor: pointer;
  position: relative; }
  .dropdown-title {
    color: #fff;
    font-weight: 400; }
  .dropdown-padding-top {
    top: 100%;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
    height: 0;
    left: -18px;
    width: 46px;
    position: absolute; }
  .dropdown-list {
    overflow: hidden;
    background: #9ccb3b;
    border-radius: 5px;
    width: 46px; }
  .dropdown-list-item {
    transition: .3s ease all; }
    .dropdown-list-item a {
      transition: .3s ease all;
      padding: 5px 10px;
      display: block;
      color: #fff;
      font-weight: 400;
      text-decoration: none; }
    .dropdown-list-item:hover, .dropdown-list-item.active {
      background: #fff; }
      .dropdown-list-item:hover a, .dropdown-list-item.active a {
        color: #9ccb3b; }
  .dropdown.js-active .dropdown-padding-top {
    height: auto;
    opacity: 1;
    visibility: visible; }

body.hover-on .dropdown:hover .dropdown-padding-top {
  opacity: 1;
  height: auto;
  visibility: visible; }

body.hover-on header a:hover:before {
  width: 100%; }

.preview-section {
  padding-top: 35px;
  min-height: 650px;
  background-image: url("../img/main-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative; }
  .preview-section .container {
    position: relative;
    z-index: 2; }
  .preview-section .logo {
    max-width: 188px;
    max-height: 188px; }
  .preview-section .bottom-content {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 220px; }
    .preview-section .bottom-content .container:before, .preview-section .bottom-content .container:after {
      content: '';
      position: absolute; }
    .preview-section .bottom-content .container:before {
      width: 148px;
      height: 113px;
      background: url("../img/zerno5.png") no-repeat center center;
      left: -3.3%;
      bottom: -22px; }
    .preview-section .bottom-content .container:after {
      width: 161px;
      height: 130px;
      right: -6.5%;
      bottom: -55px;
      background: url("../img/zerno3.png") no-repeat center center; }
  .preview-section .sun-image {
    position: absolute;
    top: 0;
    right: 80px; }
  .preview-section .bg-image {
    max-width: none;
    position: absolute;
    max-width: none;
    z-index: -1;
    bottom: -20px;
    left: -5%;
    width: 110%;
    height: 100%; }
  .preview-section .stroke-text {
    font-family: "Scada", sans-serif;
    max-width: 750px;
    margin: 0 auto;
    font-weight: 700;
    transform: rotate(-5deg);
    font-size: 89px;
    line-height: 1;
    color: #fff;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.7);
    -webkit-text-stroke: 3px #9ccb3b;
    margin-bottom: 22px;
    position: relative;
    bottom: 30px; }

.description-section {
  text-align: center;
  color: #6f902a;
  font-size: 21px;
  line-height: 36px;
  font-weight: 400;
  margin: 0 auto 20px; }

.go-top {
  display: none;
  position: fixed;
  right: 50px;
  bottom: 100px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10; }
  .go-top .arrow {
    display: inline-block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: transparent;
    text-indent: -9999px;
    border-top: 2px solid #bfbfbf;
    border-left: 2px solid #bfbfbf;
    transition: all 250ms ease-in-out;
    text-decoration: none;
    color: transparent; }
  .go-top .arrow.up {
    transform: rotate(45deg);
    left: 16px;
    top: 18px; }

footer {
  padding: 108px 0 38px;
  background: url("../img/footer-bg.png") no-repeat center;
  background-size: cover;
  position: relative; }
  footer::before {
    position: absolute;
    content: '';
    right: 10%;
    top: -26px;
    width: 71px;
    height: 53px;
    background: url("../img/zerno4.png");
    background-size: cover; }
  footer h2 {
    font-family: "Scada", sans-serif;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 59px; }
  footer .box {
    background: url("../img/bg-box.png") no-repeat center;
    background-size: contain;
    max-width: 802px;
    margin: 0 auto 62px;
    text-align: center;
    padding: 42px 0 71px;
    color: #fff; }
  footer .text {
    max-width: 516px;
    margin: 0 auto; }
    footer .text p {
      font-family: "Open Sans", sans-serif;
      font-size: 21px;
      line-height: 1.38;
      text-align: left;
      overflow: hidden;
      margin-bottom: 40px; }
    footer .text i {
      margin-right: 14px;
      float: left; }
    footer .text .info {
      white-space: nowrap; }
      footer .text .info li {
        font-family: "Open Sans", sans-serif;
        font-size: 26px;
        line-height: 1.38;
        list-style: none;
        display: inline-block;
        vertical-align: middle; }
        footer .text .info li:first-of-type {
          margin-right: 20px; }
  footer a {
    display: inline-block;
    color: #fff; }
  footer .links {
    text-align: center; }
    footer .links a {
      margin: 0 15px; }

@media screen and (max-width: 1130px) {
  .header-nav-item {
    margin: 0 15px; }
  .header .container {
    padding-left: 177px; }
  .preview-section {
    min-height: 470px; }
    .preview-section .sun-image {
      display: none; }
    .preview-section .logo {
      max-width: 100px;
      max-height: 100px; }
    .preview-section .stroke-text {
      max-width: 461px;
      font-size: 56px; }
    .preview-section .bg-image {
      height: 50%; }
    .preview-section .bottom-content .container:before, .preview-section .bottom-content .container:after {
      transform: scale(0.7); }
    .preview-section .bottom-content .container:before {
      bottom: -114px; }
    .preview-section .bottom-content .container:after {
      bottom: -100px; } }

@media screen and (max-width: 870px) {
  .header-nav-item a {
    font-size: 16px; } }

@media screen and (max-width: 768px) {
  .header.is-menu-open .hamburger--squeeze.is-active .hamburger-inner,
  .header.is-menu-open .hamburger--squeeze.is-active .hamburger-inner:after,
  .header.is-menu-open .hamburger--squeeze.is-active .hamburger-inner:before {
    background: #000; }
  .header.is-menu-open .header-dropdown {
    display: none; }
  .header.is-menu-open.js-sticky {
    background-color: rgba(0, 0, 0, 0); }
    .header.is-menu-open.js-sticky .header-dropdown {
      display: none; }
  .header.is-menu-open .header-nav {
    left: 0; }
    .header.is-menu-open .header-nav-item {
      text-align: right;
      display: block; }
      .header.is-menu-open .header-nav-item a {
        display: inline-block;
        padding: 5px 0;
        color: #000; }
        .header.is-menu-open .header-nav-item a:before {
          background: #000; }
  .header.js-sticky .hamburger-inner,
  .header.js-sticky .hamburger-inner:after,
  .header.js-sticky .hamburger-inner:before {
    background: #000; }
  .header .menu-btn {
    display: inline-block;
    z-index: 3;
    position: relative; }
  .header .container {
    padding: 0 15px; }
  .preview-section .logo {
    display: none; }
  .header-dropdown {
    margin-top: 16px;
    font-size: 16px; }
  .header-nav {
    position: absolute;
    top: 0;
    left: -100%;
    padding: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 10px 10px 50px;
    z-index: 2; } }

@media screen and (max-width: 770px) {
  footer {
    padding: 60px 0 30px; }
    footer h2 {
      font-size: 50px;
      margin-bottom: 25px; }
    footer .text p {
      font-size: 20px; }
    footer .text .info li {
      margin: 0 !important;
      width: 50%;
      float: left;
      text-align: left;
      font-size: 20px; } }

@media screen and (max-width: 620px) {
  footer .box {
    background-size: cover;
    padding: 42px 10px 71px;
    border-radius: 20%; }
    footer .box .text p {
      margin: 0 0 15px 0; }
    footer .box .text .info li {
      clear: both;
      display: block;
      float: none;
      width: 100%;
      margin-bottom: 15px !important; } }

@media screen and (max-width: 500px) {
  .preview-section {
    min-height: 400px; }
    .preview-section .stroke-text {
      max-width: 282px;
      font-size: 43px; } }

@media screen and (min-width: 769px) {
  .header-nav-item.link-stock-btn {
    background-color: #ff1414;
    padding: 5px 20px;
    border-radius: 30px;
    margin: 0; } }

@media screen and (max-width: 768px) {
  .header-nav-item.link-stock-btn a {
    color: #ff1414 !important; } }

.promo-section {
  background: url("../img/main-bg.jpg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  position: relative; }
  .promo-section .logo-wrap {
    left: 14%;
    position: absolute;
    top: 35px;
    width: 188px;
    height: 188px; }
    .promo-section .logo-wrap img {
      display: block; }
  .promo-section .side-bar {
    padding: 29px 229px 0 57px;
    position: relative;
    z-index: 3;
    float: right;
    right: -190px;
    width: 674px;
    background: url("../img/right-bg.png") no-repeat center;
    background-size: cover;
    min-height: 100vh; }
    .promo-section .side-bar .top-btns {
      margin-bottom: 34px; }
    .promo-section .side-bar .spliter {
      margin-top: 5px;
      float: left;
      position: relative;
      top: 6px;
      margin: 0 3px;
      font-family: "Scada", sans-serif;
      font-size: 18px;
      font-style: normal;
      color: #e64f28;
      line-height: 1; }
    .promo-section .side-bar .top-btn {
      margin-top: 5px;
      float: left;
      font-family: "Scada", sans-serif;
      font-size: 18px;
      font-style: normal;
      color: #e64f28;
      line-height: 1; }
    .promo-section .side-bar .menu-btn2 {
      width: 29px;
      height: 23px;
      float: right; }
      .promo-section .side-bar .menu-btn2 img {
        display: block; }
  .promo-section .bottom-wrap {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 299px;
    z-index: 2;
    width: 100%;
    background: url("../img/bottom-bg.png") no-repeat -20px 30px;
    background-size: cover; }
    .promo-section .bottom-wrap h1 {
      font-family: "Scada", sans-serif;
      max-width: 584px;
      margin: 0 auto;
      font-weight: 700;
      transform: rotate(-5deg);
      font-size: 70px;
      line-height: 1;
      color: #fff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
      -webkit-text-stroke: 3px #9ccb3b;
      position: absolute;
      left: 0;
      right: 29%;
      top: 50px; }
    .promo-section .bottom-wrap .sun {
      position: absolute;
      width: 194px;
      height: 167px;
      right: 29%;
      top: -1px; }
    .promo-section .bottom-wrap .zerno {
      position: absolute;
      width: 121px;
      height: 93px;
      bottom: 34px;
      left: 10px; }
  .promo-section .info {
    clear: both; }
    .promo-section .info h2 {
      font-family: "Scada", sans-serif;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: center;
      color: #fff; }
    .promo-section .info p {
      text-align: center;
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 23px; }
  .promo-section .icons-wrap {
    margin: 0 -5px 32px;
    position: relative;
    right: -19px; }
    .promo-section .icons-wrap .wrap {
      float: left;
      width: 25%; }
      .promo-section .icons-wrap .wrap-1, .promo-section .icons-wrap .wrap-3 {
        margin-top: 34px; }
      .promo-section .icons-wrap .wrap-4 {
        margin-top: 8px; }
        .promo-section .icons-wrap .wrap-4 img {
          width: 102px;
          height: auto; }
      .promo-section .icons-wrap .wrap .head {
        text-align: center;
        color: #f8e534;
        font-size: 19px;
        line-height: 24px;
        font-weight: normal;
        font-style: normal;
        margin-bottom: 8px; }
      .promo-section .icons-wrap .wrap img {
        margin: 0 auto;
        width: 88px;
        height: 88px;
        display: block; }
  .promo-section .bottom-box {
    clear: both;
    text-align: center;
    max-width: 350px;
    margin: 0 auto 19px;
    position: relative;
    right: -30px; }
    .promo-section .bottom-box .sub-text {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 8px;
      color: #fff; }
    .promo-section .bottom-box a.more-info {
      clear: both;
      font-size: 22px;
      line-height: 26px;
      font-weight: 600;
      color: #e64f28;
      margin-bottom: 27px;
      display: inline-block;
      text-decoration: underline; }
      .promo-section .bottom-box a.more-info:hover {
        text-decoration: none; }
    .promo-section .bottom-box a.button {
      width: 388px;
      margin-left: -20px;
      padding: 0; }
  .promo-section .timer-wrapper {
    height: 76px;
    padding: 8px 0 13px;
    width: 260px;
    margin: 0 auto 19px;
    border-radius: 5px;
    background: #f8e534; }

@media screen and (max-width: 1400px) {
  .promo-section .bottom-wrap h1 {
    font-size: 60px; }
  .promo-section .bottom-wrap .sun {
    right: 34%; } }

@media screen and (max-width: 1300px) {
  .promo-section .bottom-wrap h1 {
    right: 16%; }
  .promo-section .bottom-wrap .sun {
    display: none; } }

@media screen and (max-width: 1100px) {
  .promo-section .bottom-wrap h1 {
    right: inherit;
    left: 7%; }
  .promo-section .bottom-wrap .zerno {
    display: none; } }

@media screen and (max-width: 990px) {
  .promo-section {
    background-position: 80% center; }
    .promo-section .bottom-wrap {
      overflow: unset; }
      .promo-section .bottom-wrap h1 {
        top: -150px;
        max-width: 470px;
        left: 40px;
        transform: rotate(0); } }

@media screen and (max-width: 850px) {
  .promo-section .logo-wrap {
    left: 5%; }
  .promo-section .bottom-wrap h1 {
    max-width: 270px;
    left: 20px; } }

@media screen and (max-width: 715px) {
  .promo-section {
    background: url("../img/login-form-bg.png") no-repeat center;
    background-size: cover; }
    .promo-section .logo-wrap, .promo-section .bottom-wrap {
      display: none; }
    .promo-section .side-bar {
      right: inherit;
      width: auto;
      background: none;
      padding: 10px 15px;
      float: none;
      position: static; }
    .promo-section .icons-wrap {
      position: static;
      right: inherit;
      margin: 0 -5px 15px; }
    .promo-section .bottom-box {
      right: initial;
      max-width: none; }
      .promo-section .bottom-box a.button {
        width: auto;
        margin: 0 auto;
        padding: 0 20px; } }

@media screen and (max-width: 425px) {
  .promo-section .icons-wrap .wrap {
    display: inline-block;
    width: 49%;
    margin: 0 auto 10px;
    float: none; } }

@media screen and (max-width: 420px) {
  .promo-section .bottom-box a.button {
    line-height: 22px;
    font-size: 20px;
    padding-top: 7px; } }

.link-site-company {
  left: calc(14% + 188px);
  position: absolute;
  top: 33px;
  color: #fff;
  font-size: 18px;
  font-family: Scada,sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 1;
  display: block; }

.link-site-company-mob {
  margin-top: 15px;
  display: none; }

.link-site-company-mob a {
  color: #fff; }

@media screen and (max-width: 920px) {
  .link-site-company {
    display: none; }
  .link-site-company-mob {
    display: block; } }

.prices-section {
  background: url("../img/price-main-bg.png") no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh; }
  .prices-section .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto; }
  .prices-section .wrapper {
    padding: 36px 10% 50px; }
    .prices-section .wrapper.mechanics-wrapper {
      background: url("../img/price-bg-1.png") no-repeat center;
      background-size: contain;
      margin-bottom: 22px; }
    .prices-section .wrapper.present-wrapper {
      background: url("../img/price-bg-2.png") no-repeat center;
      background-size: contain;
      margin-bottom: 54px; }
    .prices-section .wrapper h3 {
      font-size: 34px;
      line-height: 1;
      color: #9ccb3b;
      margin-bottom: 19px;
      font-family: "Scada", sans-serif;
      font-weight: bold;
      text-align: center; }
  .prices-section h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: "Scada", sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff; }
  .prices-section h2 {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    max-width: 650px;
    margin: 0 auto 26px; }
  .prices-section .wrapper.mechanics-wrapper ol {
    list-style: none;
    counter-reset: li; }
    .prices-section .wrapper.mechanics-wrapper ol li {
      font-size: 16px;
      line-height: 38px;
      color: #373737;
      margin-bottom: 5px;
      counter-increment: li;
      position: relative;
      /*
          &:after {
            position: absolute;
            left: -7px;
            width: 1px;
            height: 1px;
            content: '.';
            color: #9ccb3b;
          }
          */ }
      .prices-section .wrapper.mechanics-wrapper ol li:before {
        content: counter(li);
        color: #9ccb3b;
        display: inline-block;
        width: 1em;
        margin-left: -1em; }
      .prices-section .wrapper.mechanics-wrapper ol li:last-of-type {
        margin-bottom: 0; }
  .prices-section .wrapper.present-wrapper .wrap {
    width: 32%;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 25px; }
    .prices-section .wrapper.present-wrapper .wrap:last-of-type {
      width: auto;
      max-width: 512px;
      display: block;
      margin: 0 auto; }
      .prices-section .wrapper.present-wrapper .wrap:last-of-type img {
        float: left;
        margin-right: 18px; }
      .prices-section .wrapper.present-wrapper .wrap:last-of-type .inner-wrap {
        padding-top: 13px;
        text-align: left; }
      .prices-section .wrapper.present-wrapper .wrap:last-of-type .text {
        text-align: left;
        font-size: 16px;
        line-height: 25px; }
      .prices-section .wrapper.present-wrapper .wrap:last-of-type .text-head {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px; }
  .prices-section .wrapper.present-wrapper img {
    height: 116px;
    display: block;
    margin: 0 auto 10px; }
  .prices-section .wrapper.present-wrapper .text {
    font-size: 16px;
    line-height: 38px;
    color: #373737;
    text-align: center; }
    .prices-section .wrapper.present-wrapper .text span {
      color: #9ccb3b; }

.additional-text {
  max-width: 525px;
  margin: 0 auto;
  text-align: center;
  font-family: "Scada", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #fff; }

@media screen and (max-width: 875px) {
  .prices-section .container {
    padding-right: 0;
    padding-left: 0;
    max-width: none; }
  .prices-section .wrapper.mechanics-wrapper, .prices-section .wrapper.present-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    background: #fff; }
  .prices-section .wrapper.mechanics-wrapper ol {
    padding-left: 30px; } }

@media screen and (max-width: 715px) {
  .prices-section h1 {
    font-size: 30px;
    line-height: 1; }
  .prices-section h2 {
    font-size: 18px;
    line-height: 26px;
    max-width: 420px; }
  .prices-section .wrapper {
    padding: 15px 10px 30px; }
    .prices-section .wrapper h3 {
      font-size: 28px;
      margin-bottom: 10px; }
    .prices-section .wrapper.mechanics-wrapper ol li {
      font-size: 15px;
      line-height: 24px; }
    .prices-section .wrapper.present-wrapper .wrap {
      display: block;
      float: none;
      width: 100%;
      max-width: 350px;
      margin: 0 auto; } }

@media screen and (max-width: 440px) {
  .prices-section .wrapper.present-wrapper .wrap:last-of-type {
    text-align: center; }
    .prices-section .wrapper.present-wrapper .wrap:last-of-type img {
      float: none;
      margin: 0 auto; }
    .prices-section .wrapper.present-wrapper .wrap:last-of-type .inner-wrap {
      text-align: center;
      max-width: 300px;
      margin: 0 auto; }
    .prices-section .wrapper.present-wrapper .wrap:last-of-type .text-head {
      text-align: center; } }

.winners-section {
  background: url("../img/price-main-bg.png") no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh; }
  .winners-section .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto; }
  .winners-section .wrapper .tabs-list a {
    text-decoration: none; }
  .winners-section .tabs-container {
    padding: 44px 10% 24px;
    background: url("../img/winners-bg.png") no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 32px; }
  .winners-section h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: "Scada", sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff; }
  .winners-section .sup-text {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    max-width: 734px;
    margin: 0 auto 25px;
    text-align: center; }
  .winners-section .empty-winners {
    text-align: left; }
  .winners-section .tabs-container img {
    float: left;
    height: 89px;
    margin-right: 40px; }
  .winners-section .tabs-container table {
    overflow: hidden;
    width: calc(100% - 142px);
    box-sizing: border-box;
    margin-bottom: 39px; }
    .winners-section .tabs-container table tr td {
      font-size: 16px;
      line-height: 38px; }
      .winners-section .tabs-container table tr td:nth-child(2), .winners-section .tabs-container table tr td:nth-child(3) {
        text-align: center; }

@media screen and (max-width: 845px) {
  .tabs-list-item {
    width: 44%;
    margin: 0 2% 10px; } }

@media screen and (max-width: 715px) {
  .winners-section h1 {
    font-size: 30px;
    line-height: 1; }
  .winners-section .sup-text {
    font-size: 20px;
    line-height: 24px; }
  .winners-section .tabs-container {
    padding: 25px 15px 15px; }
    .winners-section .tabs-container img {
      display: none; }
    .winners-section .tabs-container .inner {
      overflow: scroll; }
    .winners-section .tabs-container table {
      text-align: center;
      width: 100%;
      min-width: 450px; } }

.personal-section {
  background: url("../img/price-main-bg.png") no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh; }
  .personal-section .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto; }
  .personal-section h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: "Scada", sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff; }
  .personal-section .user-info {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 23px;
    color: #fff;
    text-align: center; }
    .personal-section .user-info span {
      font-weight: bold; }
  .personal-section .wrapper {
    padding: 44px 10% 24px;
    background: url("../img/winners-bg.png") no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 200px;
    position: relative; }
    .personal-section .wrapper h3 {
      font-size: 34px;
      line-height: 1;
      color: #9ccb3b;
      margin-bottom: 17px;
      font-family: "Scada", sans-serif;
      font-weight: bold;
      text-align: center; }
    .personal-section .wrapper .info-text {
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      color: #e64f28;
      margin-bottom: 33px; }
    .personal-section .wrapper .empty-codes {
      text-align: center; }
  .personal-section table {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 39px; }
    .personal-section table tr td {
      font-size: 16px;
      line-height: 38px; }
      .personal-section table tr td:nth-child(2), .personal-section table tr td:nth-child(3) {
        text-align: center; }
    .personal-section table tr:last-of-type td {
      padding-bottom: 0; }
  .personal-section .card-wrap {
    position: absolute;
    width: 333px;
    right: -297px;
    bottom: 0; }
    .personal-section .card-wrap img {
      width: 126px;
      height: 126px;
      display: block;
      clear: both; }
    .personal-section .card-wrap .text {
      float: right;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      max-width: 266px;
      position: relative;
      font-family: "Scada", sans-serif;
      font-weight: bold;
      margin-bottom: 50px; }
      .personal-section .card-wrap .text::before {
        content: '';
        position: absolute;
        left: 22%;
        top: 100%;
        width: 92px;
        height: 82px;
        background: url("../img/arrow.png") no-repeat center;
        background-size: 100% 100%; }

@media screen and (max-width: 1420px) {
  .personal-section .card-wrap {
    right: 0;
    bottom: -146px;
    width: auto; }
    .personal-section .card-wrap .wrapper {
      margin-bottom: 130px; }
    .personal-section .card-wrap img {
      display: inline-block; }
    .personal-section .card-wrap .text {
      display: inline-block;
      float: none; }
      .personal-section .card-wrap .text::before {
        left: 69%;
        top: 48%;
        transform: scale(-1, 1) rotate(43deg); } }

@media screen and (max-width: 875px) {
  .personal-section .container {
    padding-right: 0;
    padding-left: 0;
    max-width: none; }
  .personal-section .wrapper {
    padding-left: 10px;
    padding-right: 10px;
    background: #fff; } }

@media screen and (max-width: 715px) {
  .personal-section h1 {
    font-size: 30px;
    line-height: 1; }
  .personal-section .user-info {
    font-size: 19px;
    line-height: 29px; }
  .personal-section .wrapper {
    padding: 15px 10px 30px; }
    .personal-section .wrapper h3 {
      font-size: 29px;
      margin-bottom: 12px; }
    .personal-section .wrapper .info-text {
      margin-bottom: 15px; }
  .personal-section .inner {
    overflow: scroll; }
  .personal-section table {
    text-align: center;
    width: 100%;
    min-width: 450px; } }

@media screen and (max-width: 425px) {
  .personal-section .card-wrap {
    bottom: -212px; }
    .personal-section .card-wrap .wrapper {
      margin-bottom: 130px; }
    .personal-section .card-wrap img {
      display: inline-block; }
    .personal-section .card-wrap .text {
      padding: 0 10px;
      display: block;
      max-width: none;
      margin-bottom: 5px; }
      .personal-section .card-wrap .text::before {
        left: 49%;
        top: 90%;
        transform: scaleX(1) rotate(3deg); } }

.news-section {
  background: url("../img/bg.png") no-repeat center;
  background-size: cover;
  text-align: center;
  padding: 35px 0 70px;
  min-height: 100vh; }
  .news-section .back-link {
    font-family: "Scada", sans-serif;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    margin-bottom: 34px;
    display: inline-block; }
    .news-section .back-link img {
      width: 22px;
      height: 14px;
      margin-right: 8px; }
  .news-section .container {
    text-align: left;
    background: #fff;
    max-width: 1060px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 45px 110px; }
  .news-section h1 {
    font-size: 30px;
    line-height: 40px;
    color: #373737;
    font-weight: bold;
    font-family: "Scada", sans-serif;
    margin-bottom: 19px; }
  .news-section .date {
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    margin-bottom: 19px; }
  .news-section p {
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    margin-bottom: 20px; }
  .news-section .img-wrapper {
    margin-bottom: 33px; }
    .news-section .img-wrapper img {
      float: left;
      width: 48%;
      margin: 0 1%;
      display: block; }
  .news-section .slider {
    border-top: 1px solid #9ccb3b;
    padding-top: 40px; }

@media screen and (max-width: 1100px) {
  .news-section .container {
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 15px 20px; } }

@media screen and (max-width: 715px) {
  .news-section h1 {
    font-size: 30px;
    line-height: 1; } }

@media screen and (max-width: 640px) {
  .news-section h1 {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 10px; }
  .news-section .date {
    margin-bottom: 10px; }
  .news-section p {
    margin-bottom: 10px; }
  .news-section .slider {
    padding-top: 10px; } }

.registration-section {
  background: url("../img/price-main-bg.png") no-repeat center;
  background-size: cover;
  padding: 52px 0 180px;
  min-height: 100vh; }
  .registration-section .container {
    max-width: 564px;
    padding: 0 10px;
    margin: 0 auto; }
  .registration-section h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: "Scada", sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff; }
  .registration-section .sup-text {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    max-width: 734px;
    text-align: center; }
  .registration-section .sup-text-red {
    margin: 0 auto 25px;
    text-align: center;
    color: #e64f28;
    font-size: 13px; }
  .registration-section .form-wrapper {
    max-width: 423px;
    padding: 0 10px;
    margin: 0 auto;
    text-align: center; }
  .registration-section .tooltip-wrap {
    max-width: 347px;
    background: #fff;
    padding: 20px 35px;
    margin: 0 auto 15px;
    text-align: center;
    border: 2px solid #e3b87d;
    border-radius: 10px; }
    .registration-section .tooltip-wrap .head {
      color: #8bba17;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
      font-family: "Scada", sans-serif;
      font-weight: bold; }
    .registration-section .tooltip-wrap p {
      font-size: 14px;
      line-height: 21px;
      color: #373737;
      margin-bottom: 15px; }
    .registration-section .tooltip-wrap img {
      display: block;
      margin: 0 auto; }
  .registration-section .field {
    position: relative;
    max-width: 403px;
    margin: 0 auto 44px; }
    .registration-section .field button {
      position: absolute;
      top: 0;
      right: 0; }
    .registration-section .field input {
      border-radius: 30px;
      height: 60px;
      line-height: 60px;
      text-align: left;
      font-size: 24px; }
      .registration-section .field input::placeholder {
        color: #cdcbcb; }
    .registration-section .field .register-success {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      margin-top: 10px; }
    .registration-section .field .register-error {
      color: #e64f28;
      font-size: 22px;
      font-weight: bold;
      margin-top: 10px; }
  .registration-section .card-wrap {
    color: #fff;
    max-width: 410px;
    margin: 0 auto; }
    .registration-section .card-wrap .text {
      float: left;
      font-size: 18px;
      line-height: 24px;
      font-family: "Scada", sans-serif;
      font-weight: bold;
      width: calc(100% - 106px); }
    .registration-section .card-wrap img {
      margin-left: 15px;
      float: right;
      width: 91px;
      display: block; }

@media screen and (max-width: 715px) {
  .registration-section h1 {
    font-size: 30px;
    line-height: 1; }
  .registration-section .sup-text {
    font-size: 20px;
    line-height: 24px; }
  .registration-section .field {
    margin-bottom: 20px; }
    .registration-section .field input {
      line-height: 45px;
      height: 45px;
      font-size: 20px; }
    .registration-section .field button {
      height: 45px;
      line-height: 45px; }
  .registration-section .tooltip-wrap {
    padding: 10px 15px; } }

@media screen and (max-width: 410px) {
  .registration-section .field input {
    text-align: center;
    margin-bottom: 10px; }
  .registration-section .field button {
    position: static; } }

.about-section {
  padding-top: 44px;
  padding-bottom: 46px; }
  .about-section .left-img-box,
  .about-section .text,
  .about-section .right-img-box {
    text-align: center;
    float: left; }
    .about-section .left-img-box img,
    .about-section .text img,
    .about-section .right-img-box img {
      display: block; }
  .about-section .right-img-box {
    width: 28%;
    float: right;
    position: relative; }
    .about-section .right-img-box:after {
      width: 41px;
      height: 34px;
      content: '';
      position: absolute;
      bottom: -10px;
      right: 20px;
      background: url("../img/zerno7.png") no-repeat center;
      background-size: contain; }
  .about-section .left-img-box {
    width: 25%; }
  .about-section .text {
    width: 47%;
    padding: 97px 10px 0; }
    .about-section .text p {
      max-width: 475px;
      position: relative;
      text-align: center;
      color: #6f902a;
      font-size: 26px;
      line-height: 36px;
      font-weight: 400;
      margin: 0 auto; }
      .about-section .text p:before {
        content: '';
        position: absolute;
        top: -98px;
        right: 97px;
        width: 79px;
        height: 47px;
        background: url("../img/zerno1.png") no-repeat center;
        background-size: contain; }
      .about-section .text p:after {
        width: 74px;
        height: 95px;
        content: '';
        position: absolute;
        bottom: -133px;
        right: 39%;
        background: url("../img/zerno2.png") no-repeat center;
        background-size: contain; }
  .about-section-logo-img {
    margin-top: 11px;
    margin-bottom: 32px;
    margin-right: auto; }
  .about-section-exp-img {
    margin-right: auto; }
  .about-section-halva-img {
    margin-left: auto; }

@media screen and (max-width: 500px) {
  .about-section .right-img-box:after {
    transform: scale(0.7);
    bottom: -23px;
    right: -8px; } }

.info-section {
  position: relative;
  padding-bottom: 72px; }
  .info-section .info-section-bg-image {
    width: 106%;
    left: -3%;
    display: block;
    max-width: none;
    position: absolute;
    top: 0;
    z-index: 1;
    min-height: 770px; }
  .info-section .container {
    position: relative;
    z-index: 2;
    margin-bottom: 88px; }
  .info-section .bg-wrap {
    min-height: 951px; }
  .info-section .text {
    width: 50%;
    padding-top: 200px;
    float: left; }
    .info-section .text p {
      max-width: 608px;
      font-size: 26px;
      line-height: 40px;
      color: #fff; }
  .info-section .img-box {
    margin-right: 70px;
    width: 328px;
    float: right; }
    .info-section .img-box img {
      width: 106px;
      display: inline-block;
      vertical-align: middle;
      position: relative; }
    .info-section .img-box .pic1 {
      width: 106px; }
    .info-section .img-box .pic2 {
      width: 106px;
      top: -19px; }
    .info-section .img-box .pic3 {
      width: 106px; }
    .info-section .img-box .pic4 {
      width: 106px;
      right: -18px;
      z-index: 2; }
    .info-section .img-box .pic4,
    .info-section .img-box .pic6 {
      top: -67px; }
    .info-section .img-box .pic5 {
      width: 106px;
      top: -86px;
      z-index: 1; }
    .info-section .img-box .pic6 {
      width: 106px;
      left: -18px; }
  .info-section h2 {
    clear: both;
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    font-family: "Scada", sans-serif;
    margin-left: 45%;
    line-height: 1;
    position: relative; }
    .info-section h2:before {
      position: absolute;
      content: '';
      top: 35px;
      right: calc(100% + 29px);
      width: 184px;
      height: 107px;
      background: url("../img/arrow-down.png") no-repeat center; }
  .info-section .info-gif-image,
  .info-section .info-gif-image-mobile {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 4; }
  .info-section .info-gif-image-mobile {
    display: none; }

@media screen and (max-width: 1250px) {
  .info-section .info-gif-image,
  .info-section .info-gif-image-mobile {
    max-width: 100%; } }

@media screen and (max-width: 1140px) {
  .info-section h2 {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7); } }

@media screen and (max-width: 960px) {
  .about-section .left-img-box {
    width: 50%;
    margin: 0 auto; }
  .about-section .text {
    width: 50%; }
  .about-section .right-img-box {
    width: 50%;
    float: none;
    margin: 0 auto; } }

@media screen and (max-width: 840px) {
  .info-section .info-section-bg-image {
    min-height: 1400px; }
  .info-section .info-gif-image {
    display: none; }
  .info-section .info-gif-image-mobile {
    display: block;
    width: 390px; } }

@media screen and (max-width: 768px) {
  .info-section .text {
    float: none;
    width: 100%;
    margin: 0 auto 60px; }
    .info-section .text p {
      text-align: center;
      font-size: 22px;
      line-height: 28px;
      margin: 0 auto 15px; }
  .info-section .img-box {
    margin: 0 auto;
    float: none; } }

@media screen and (max-width: 755px) {
  .about-section .left-img-box,
  .about-section .text .right-img-box {
    width: 50%;
    float: none;
    margin: 0 auto; }
    .about-section .left-img-box img,
    .about-section .text .right-img-box img {
      margin: 0 auto; }
  .about-section .text {
    width: 100%;
    padding: 0;
    margin: 35px auto; }
    .about-section .text p::before, .about-section .text p:after {
      display: none; }
  .about-section-logo-img {
    margin-bottom: 20px !important; } }

@media screen and (max-width: 800px) {
  .info-section h2:before {
    right: initial;
    top: 17px;
    left: -94px;
    width: 103px;
    height: 107px;
    background-size: contain;
    transform: rotate(-29deg); } }

@media screen and (max-width: 470px) {
  .about-section .text p {
    font-size: 20px;
    line-height: 24px; } }

@media screen and (max-width: 400px) {
  .info-section .img-box {
    width: 310px;
    height: 452px;
    text-align: center; }
    .info-section .img-box img {
      position: absolute;
      top: 100px !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      margin: auto; }
      .info-section .img-box img.pic1, .info-section .img-box img.pic4 {
        right: 170px !important; }
      .info-section .img-box img.pic5, .info-section .img-box img.pic2 {
        top: 70px !important; }
      .info-section .img-box img.pic3, .info-section .img-box img.pic6 {
        left: 170px !important; }
      .info-section .img-box img.pic4, .info-section .img-box img.pic6 {
        top: 500px !important; }
      .info-section .img-box img.pic5 {
        top: 470px !important; } }

@media screen and (max-width: 440px) {
  .info-section h2 {
    margin-left: 30%;
    font-size: 40px; } }

.fb-phone-agree {
  margin-bottom: 15px;
  margin-top: 15px; }

.products-section {
  text-align: center; }
  .products-section .img-box img {
    display: inline-block;
    vertical-align: middle;
    margin: 2px 6px; }
  .products-section h2 {
    font-size: 60px;
    line-height: 1.1;
    color: #9ccb3b;
    margin-bottom: 20px;
    font-family: "Scada", sans-serif; }
  .products-section .sub-text {
    color: #373737;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px; }

.products-section .tabs-container {
  position: relative; }

.products-section .tabs-list {
  padding: 10px 0;
  max-width: 1220px;
  padding: 0 15px;
  margin: 0 auto 30px; }

.products-section .tabs-list-item {
  cursor: pointer;
  margin: 0 33px;
  width: 238px;
  height: 104px;
  display: inline-block;
  vertical-align: middle;
  color: #9ccb3b;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='240' height='106' viewBox='0 0 240 106' enable-background='new 0 0 240 106' xml:space='preserve'%3E%3Cpath fill='%23ffffff' stroke='none' d='M 0,53 C 0,0 0,0 120,0 S 240,0 240,53 240,106 120,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
  background-size: 240px 106px;
  background-repeat: no-repeat;
  position: relative;
  position: relative;
  transition: 0.3s ease all;
  text-align: center;
  padding-top: 28px;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold; }
  .products-section .tabs-list-item:after {
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='240' height='106' viewBox='0 0 240 106' enable-background='new 0 0 240 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 120,0 S 240,0 240,53 240,106 120,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
    background-size: 240px 106px;
    background-repeat: no-repeat;
    position: absolute;
    width: 240px;
    height: 106px;
    z-index: -1;
    content: "";
    left: -3px;
    top: -3px; }
  .products-section .tabs-list-item:before {
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='240' height='106' viewBox='0 0 240 106' enable-background='new 0 0 240 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 120,0 S 240,0 240,53 240,106 120,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
    background-size: 240px 106px;
    background-repeat: no-repeat;
    position: absolute;
    width: 240px;
    height: 106px;
    z-index: -1;
    content: "";
    left: 3px;
    top: 3px; }
  .products-section .tabs-list-item:hover, .products-section .tabs-list-item.js-active {
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='240' height='106' viewBox='0 0 240 106' enable-background='new 0 0 240 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 120,0 S 240,0 240,53 240,106 120,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
    background-size: 240px 106px;
    background-repeat: no-repeat;
    color: #fff; }
  .products-section .tabs-list-item span {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    display: block; }
  .products-section .tabs-list-item.second {
    width: 291px;
    height: 104px;
    height: 106px;
    display: inline-block;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='294' height='106' viewBox='0 0 294 106' enable-background='new 0 0 294 106' xml:space='preserve'%3E%3Cpath fill='white' stroke='none' d='M 0,53 C 0,0 0,0 147,0 S 294,0 294,53 294,106 147,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
    background-size: 294px 106px;
    background-repeat: no-repeat;
    position: relative;
    position: relative; }
    .products-section .tabs-list-item.second:after {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='294' height='106' viewBox='0 0 294 106' enable-background='new 0 0 294 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 147,0 S 294,0 294,53 294,106 147,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
      background-size: 294px 106px;
      background-repeat: no-repeat;
      position: absolute;
      width: 294px;
      height: 106px;
      z-index: -1;
      content: "";
      left: -3px;
      top: -3px; }
    .products-section .tabs-list-item.second:before {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='294' height='106' viewBox='0 0 294 106' enable-background='new 0 0 294 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 147,0 S 294,0 294,53 294,106 147,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
      background-size: 294px 106px;
      background-repeat: no-repeat;
      position: absolute;
      width: 294px;
      height: 106px;
      z-index: -1;
      content: "";
      left: 3px;
      top: 3px; }
    .products-section .tabs-list-item.second:hover, .products-section .tabs-list-item.second.js-active {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='294' height='106' viewBox='0 0 294 106' enable-background='new 0 0 294 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 147,0 S 294,0 294,53 294,106 147,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
      background-size: 294px 106px;
      background-repeat: no-repeat; }
  .products-section .tabs-list-item.third {
    width: 426px;
    height: 106px;
    height: 108px;
    display: inline-block;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='428' height='108' viewBox='0 0 428 108' enable-background='new 0 0 428 108' xml:space='preserve'%3E%3Cpath fill='white' stroke='none' d='M 0,54 C 0,0 0,0 214,0 S 428,0 428,54 428,108 214,108 0,108 0,54'%3E%3C/path%3E%3C/svg%3E");
    background-size: 428px 108px;
    background-repeat: no-repeat;
    position: relative;
    position: relative; }
    .products-section .tabs-list-item.third:after {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='428' height='108' viewBox='0 0 428 108' enable-background='new 0 0 428 108' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,54 C 0,0 0,0 214,0 S 428,0 428,54 428,108 214,108 0,108 0,54'%3E%3C/path%3E%3C/svg%3E");
      background-size: 428px 108px;
      background-repeat: no-repeat;
      position: absolute;
      width: 428px;
      height: 108px;
      z-index: -1;
      content: "";
      left: -3px;
      top: -3px; }
    .products-section .tabs-list-item.third:before {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='428' height='108' viewBox='0 0 428 108' enable-background='new 0 0 428 108' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,54 C 0,0 0,0 214,0 S 428,0 428,54 428,108 214,108 0,108 0,54'%3E%3C/path%3E%3C/svg%3E");
      background-size: 428px 108px;
      background-repeat: no-repeat;
      position: absolute;
      width: 428px;
      height: 108px;
      z-index: -1;
      content: "";
      left: 3px;
      top: 3px; }
    .products-section .tabs-list-item.third:hover, .products-section .tabs-list-item.third.js-active {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='426' height='106' viewBox='0 0 426 106' enable-background='new 0 0 426 106' xml:space='preserve'%3E%3Cpath fill='%239ccb3b' stroke='none' d='M 0,53 C 0,0 0,0 213,0 S 426,0 426,53 426,106 213,106 0,106 0,53'%3E%3C/path%3E%3C/svg%3E");
      background-size: 426px 106px;
      background-repeat: no-repeat; }

.products-section .tabs-container-tab {
  min-height: 660px;
  background: url("../img/section-bg1.png") no-repeat left center;
  background-size: cover;
  display: none; }
  .products-section .tabs-container-tab.js-active {
    display: block; }
  .products-section .tabs-container-tab .inner {
    max-width: 940px;
    margin: 0 0 0 33%;
    padding-bottom: 138px;
    position: relative;
    padding: 0 40px 138px; }
    .products-section .tabs-container-tab .inner .pic1 {
      width: 127px;
      height: 284px; }
    .products-section .tabs-container-tab .inner .pic2 {
      width: 137px;
      height: 303px; }
    .products-section .tabs-container-tab .inner .pic3 {
      width: 131px;
      height: 293px; }
    .products-section .tabs-container-tab .inner .pic4 {
      width: 105px;
      height: 232px; }
    .products-section .tabs-container-tab .inner .pic5 {
      width: 102px;
      height: 231px; }
    .products-section .tabs-container-tab .inner .pic6 {
      width: 103px;
      height: 229px; }
    .products-section .tabs-container-tab .inner .pic7 {
      width: 104px;
      height: 229px; }
    .products-section .tabs-container-tab .inner .pic8 {
      width: 103px;
      height: 228px; }
    .products-section .tabs-container-tab .inner .pic9 {
      width: 103px;
      height: 230px; }
    .products-section .tabs-container-tab .inner .pic10 {
      width: 101px;
      height: 226px; }
    .products-section .tabs-container-tab .inner .pic11 {
      width: 128px;
      height: 282px; }
    .products-section .tabs-container-tab .inner .pic12 {
      width: 130px;
      height: 290px; }
    .products-section .tabs-container-tab .inner .pic13 {
      width: 128px;
      height: 282px; }
  .products-section .tabs-container-tab .massa-img {
    margin-bottom: 60px; }
  .products-section .tabs-container-tab .round-img {
    margin-bottom: 15px; }
  .products-section .tabs-container-tab .round-text {
    display: none;
    color: #54a640;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px; }
  .products-section .tabs-container-tab .marks {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 37px; }

.products-section .tabs-container-tab.second {
  background-image: url("../img/section-bg2.png") !important; }

.products-section .tabs-container-tab.third {
  background-image: url("../img/section-bg3.png") !important; }

.products-section .tab-next,
.products-section .tab-prev {
  position: absolute;
  margin: auto;
  width: 36px;
  height: 53px;
  top: 320px;
  left: 0;
  cursor: pointer;
  z-index: 5;
  text-indent: -9999px;
  background: url("../img/arrow-left-2.png") no-repeat center !important; }

.products-section .tab-next {
  left: inherit;
  right: 20px;
  background-image: url("../img/arrow-right-2.png") !important; }

@media screen and (max-width: 1200px) {
  .products-section .tabs-list {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .products-section .tabs-list-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 33% !important;
    height: 100px !important;
    padding: 0 !important;
    border: 3px solid #9ccb3b;
    margin: 0 auto;
    border-radius: 10% 10% 0 0;
    border-bottom: 0;
    text-align: center;
    background: #fff !important; }
    .products-section .tabs-list-item::before, .products-section .tabs-list-item:after {
      display: none !important; }
    .products-section .tabs-list-item.js-active, .products-section .tabs-list-item:hover {
      background: #9ccb3b !important; }
      .products-section .tabs-list-item.js-active::before, .products-section .tabs-list-item.js-active:after, .products-section .tabs-list-item:hover::before, .products-section .tabs-list-item:hover:after {
        display: none !important; }
    .products-section .tabs-list-item.second {
      margin: 0 -9px; } }

@media screen and (max-width: 1100px) {
  .products-section .tabs-container-tab .inner {
    max-width: none;
    margin: 0; }
    .products-section .tabs-container-tab .inner .round-img {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-radius: 15px; }
  .products-section .tab-prev {
    left: 30px; } }

@media screen and (max-width: 980px) {
  .products-section .tabs-container-tab .img-box {
    max-width: 494px;
    margin: 0 auto; } }

@media screen and (max-width: 860px) {
  .products-section .tabs-container-tab .round-img {
    display: none; }
  .products-section .tabs-container-tab .img-box {
    max-width: 400px; }
  .products-section .tabs-container-tab .round-text {
    max-width: 80%;
    border-radius: 15px;
    margin: 0 auto 30px;
    font-weight: bold;
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 27px; } }

@media screen and (max-width: 670px) {
  .products-section .tabs-container-tab .round-img {
    display: none; }
  .products-section .tabs-container-tab .round-text {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 27px; }
  .products-section .tabs-list-item {
    height: 75px !important;
    font-size: 18px; }
    .products-section .tabs-list-item span {
      display: none; }
  .products-section .tab-next,
  .products-section .tab-prev {
    top: calc(50% - 36px); }
  .products-section .tab-next {
    right: 5px; }
  .products-section .tab-prev {
    left: 5px; } }
