@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.promo-section {
  background: url('../img/main-bg.jpg') no-repeat center;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  .logo-wrap {
    left: 14%;
    position: absolute;
    top: 35px;
    width: 188px;
    height: 188px;
    img {
      display: block;
    }
  }
  .side-bar {
    padding: 29px 229px 0 57px;
    position: relative;
    z-index: 3;
    float: right;
    right: -190px;
    width: 674px;
    background: url('../img/right-bg.png') no-repeat center;
    background-size: cover;
    min-height: 100vh;
    .top-btns {
      margin-bottom: 34px;
    }
    .spliter {
      margin-top: 5px;
      float: left;
      position: relative;
      top: 6px;
      margin: 0 3px;
      font-family: $Scada;
      font-size: 18px;
      font-style: normal;
      color: $red2;
      line-height: 1;
    }
    .top-btn {
      margin-top: 5px;
      float: left;
      font-family: $Scada;
      font-size: 18px;
      font-style: normal;
      color: $red2;
      line-height: 1;
    }
    .menu-btn2 {
      width: 29px;
      height: 23px;
      float: right;
      img {
        display: block;
      }
    }
  }
  .bottom-wrap {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 299px;
    z-index: 2;
    width: 100%;
    background: url('../img/bottom-bg.png') no-repeat -20px 30px;
    background-size: cover;
    h1 {
      font-family: $Scada;
      max-width: 584px;
      margin: 0 auto;
      font-weight: 700;
      transform: rotate(-5deg);
      font-size: 70px;
      line-height: 1;
      color: #fff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, .7);
      -webkit-text-stroke: 3px #9ccb3b;
      position: absolute;
      left: 0;
      right: 29%;
      top: 50px;
    }
    .sun {
      position: absolute;
      width: 194px;
      height: 167px;
      right: 29%;
      top: -1px;
    }
    .zerno {
      position: absolute;
      width: 121px;
      height: 93px;
      bottom: 34px;
      left: 10px;
    }
  }
  .info {
    clear: both;
    h2 {
      font-family: $Scada;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }
    p {
      text-align: center;
      color: #fff;
      font-family: $OpenSans;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 23px;
    }
  }
  .icons-wrap {
    margin: 0 -5px 32px;
    position: relative;
    right: -19px;
    .wrap {
      float: left;
      width: 25%;
      &-1,
      &-3 {
        margin-top: 34px;
      }
      &-4 {
        margin-top: 8px;
        img {
          width: 102px;
          height: auto;
        }
      }
      .head {
        text-align: center;
        color: $yellow;
        font-size: 19px;
        line-height: 24px;
        font-weight: normal;
        font-style: normal;
        margin-bottom: 8px;
      }
      img {
        margin: 0 auto;
        width: 88px;
        height: 88px;
        display: block;
      }
    }
  }
  .bottom-box {
    clear: both;
    text-align: center;
    max-width: 350px;
    margin: 0 auto 19px;
    position: relative;
    right: -30px;
    .sub-text {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 8px;
      color: #fff;
    }
    a.more-info {
      clear: both;
      font-size: 22px;
      line-height: 26px;
      font-weight: 600;
      color: $red2;
      margin-bottom: 27px;
      display: inline-block;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    a.button {
      width: 388px;
      margin-left: -20px;
      padding: 0;
    }
  }
  .timer-wrapper {
    height: 76px;
    padding: 8px 0 13px;
    width: 260px;
    margin: 0 auto 19px;
    border-radius: 5px;
    background: $yellow;
  }
}



@media screen and (max-width: 1400px) {
  .promo-section {
    .bottom-wrap {
      h1 {
        font-size: 60px;
      }
      .sun {
        right: 34%;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .promo-section {
    .bottom-wrap {
      h1 {
        right: 16%;
      }
      .sun {
        display: none
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .promo-section {
    .bottom-wrap {
      h1 {
        right: inherit;
        left: 7%;
      }
      .zerno {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .promo-section {
    background-position: 80% center;
    .bottom-wrap {
      overflow: unset;
      h1 {
        top:  -150px;
        max-width: 470px;
        left: 40px;
        transform: rotate(0);
      }
    }
  }
}


@media screen and (max-width: 850px) {
  .promo-section {
    .logo-wrap {
      left: 5%;
    }
    .bottom-wrap {
      h1 {
        max-width: 270px;
        left: 20px;
      }
    }
  }
}

@media screen and (max-width: 715px) { 
  .promo-section {
    background: url('../img/login-form-bg.png') no-repeat center;
    background-size: cover;
    .logo-wrap, .bottom-wrap {
      display: none;
    }
    .side-bar {
      right: inherit;
      width: auto;
      background: none;
      padding: 10px 15px;
      float: none;
      position: static;
    }
    .icons-wrap {
      position: static;
      right: inherit;
      margin: 0 -5px 15px;
    }
    .bottom-box {
      right: initial;
      max-width: none;
      a.button {
        width: auto;
        margin: 0 auto;
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .promo-section .icons-wrap .wrap {
    display: inline-block;
    width: 49%;
    margin: 0 auto 10px;
    float: none;
  }
}

@media screen and (max-width: 420px) {
  .promo-section {
    .bottom-box {
      a.button {
        line-height: 22px;
        font-size: 20px;
        padding-top: 7px;
      }
    }
  }
}

.link-site-company {
  left: calc(14% + 188px);
  position: absolute;
  top: 33px;
  color: #fff;
  font-size: 18px;
  font-family: Scada,sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 1;
  display: block;
}
.link-site-company-mob {
  margin-top: 15px;
  display: none;
}
.link-site-company-mob a {
  color: #fff;
}

@media screen and (max-width: 920px) {
  .link-site-company {
    display: none;
  }
  .link-site-company-mob {
    display: block;
  }
}