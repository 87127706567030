@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';

.personal-section {
  background: url('../img/price-main-bg.png') no-repeat center;
  background-size: cover;
  padding: 52px 0 40px;
  min-height: 100vh;
  .container {
    max-width: 830px;
    padding: 0 10px;
    margin: 0 auto;
  }
  h1 {
    font-size: 50px;
    line-height: 30px;
    margin-bottom: 18px;
    font-family: $Scada;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .user-info {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 23px;
    color: #fff;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
  .wrapper {
    padding: 44px 10% 24px;
    background: url('../img/winners-bg.png') no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 200px;
    position: relative;
    h3 {
      font-size: 34px;
      line-height: 1;
      color: #9ccb3b;
      margin-bottom: 17px;
      font-family: $Scada;
      font-weight: bold;
      text-align: center;
    }
    .info-text {
      text-align: center;
      font-size: 16px;
      line-height: 30px;;
      color: #e64f28;
      margin-bottom: 33px;
    }
    .empty-codes {
      text-align: center;
    }
  }
  table {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 39px;
    tr {
      td {
        font-size: 16px;
        line-height: 38px;
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;
        }
      }
      &:last-of-type {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
  .card-wrap {
    position: absolute;
    width: 333px;
    right: -297px;
    bottom: 0;
    img {
      width: 126px;
      height: 126px;
      display: block;
      clear: both;
    }
    .text {
      float: right;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      max-width: 266px;
      position: relative;
      font-family: $Scada;
      font-weight: bold;
      margin-bottom: 50px;
      &::before {
        content: '';
        position: absolute;
        left: 22%;
        top: 100%;
        width: 92px;
        height: 82px;
        background: url('../img/arrow.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}

@media screen and (max-width: 1420px) {
  .personal-section {
   .card-wrap {
    right: 0;
    bottom: -146px;
    width: auto;
    .wrapper {
      margin-bottom: 130px;
    }
    img {
      display: inline-block;
    }
    .text {
      display: inline-block;
      float: none;
      &::before {
        left: 69%;
        top: 48%;    
        transform: scale(-1, 1) rotate(43deg);
      }
    }
   }
  }
}


@media screen and (max-width: 875px) {
  .personal-section {
    .container {
      padding-right: 0;
      padding-left: 0;
      max-width: none;
    }
    .wrapper {
      padding-left: 10px;
      padding-right: 10px;
      background: #fff;
    }
  }
}


@media screen and (max-width: 715px) {
  .personal-section {
    h1 {
      font-size: 30px;
      line-height: 1;
    }
    .user-info {
      font-size: 19px;
      line-height: 29px;
    }
    .wrapper {
      padding: 15px 10px 30px;
      h3 {
        font-size: 29px;
        margin-bottom: 12px;
      }
      .info-text {
        margin-bottom: 15px;
      }
    }
    .inner {
      overflow: scroll;
    }
    table {
      text-align: center;
      width: 100%;
      min-width: 450px;
    }
  }
}


@media screen and (max-width: 425px) {
  .personal-section {
   .card-wrap {
    bottom: -212px;
    .wrapper {
      margin-bottom: 130px;
    }
    img {
      display: inline-block;
    }
    .text {
      padding: 0 10px;
      display: block;
      max-width: none;
      margin-bottom: 5px;
      &::before {
        left: 49%;
        top: 90%;
        transform: scaleX(1) rotate(3deg);
      }
    }
   }
  }
}