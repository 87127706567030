@import './components/colors.scss';
@import './components/mixins';
@import './components/vars';
.news-section {
  background: url('../img/bg.png') no-repeat center;
  background-size: cover;
  text-align: center;
  padding: 35px 0 70px;
  min-height: 100vh;
  .back-link {
    font-family: $Scada;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    margin-bottom: 34px;
    display: inline-block;
    img {
      width: 22px;
      height: 14px;
      margin-right: 8px;
    }
  }
  .container {
    text-align: left;
    background: #fff;
    max-width: 1060px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 45px 110px;
  }
  h1 {
    font-size: 30px;
    line-height: 40px;
    color: #373737;
    font-weight: bold;
    font-family: $Scada;
    margin-bottom: 19px;
  }
  .date {
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    margin-bottom: 19px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    margin-bottom: 20px;
  }
  .img-wrapper {
    margin-bottom: 33px;
    img {
      float: left;
      width: 48%;
      margin: 0 1%;
      display: block;
    }
  }
  .slider {
    border-top: 1px solid #9ccb3b;
    padding-top: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .news-section {
    .container {
      width: calc(100% - 30px);
      margin: 0 15px;
      padding: 15px 20px;
    }
  }
}

@media screen and (max-width: 715px) {
  .news-section {
    h1 {
      font-size: 30px;
      line-height: 1;
    }
  }
}

@media screen and (max-width: 640px) {
  .news-section {
    h1 {
      font-size: 26px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    .date {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    .slider {
      padding-top: 10px;
    }
  }
}